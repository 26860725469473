import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import moment from "moment";

import { Grid, Typography, Box, Button, Tooltip } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useLottie } from "lottie-react";

import Menu from "./menu";
import Bubble from "../../components/Bubble";
import Results from "../quizView/components/Results";
import SplashScreen from "../../components/SplashScreen";

import { actions } from "../logInScreen/slice";
import { db, auth } from "../../firebase";
import Score from "../../assets/lotties/score.json";
import { useTheme } from "@mui/styles";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const sortHistory = (history) => {
  if (history && history.length) {
    return [...history].sort(
      (a, b) =>
        moment(b.date).format("YYYYMMDD") - moment(a.date).format("YYYYMMDD")
    );
  }
  return [];
};

const displayDate = (date) => {
  return moment(date, "YYYY/MM/DD").format("DD MMMM YYYY");
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function HomePage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    config: { name, quizHistory, currentQuiz },
  } = useSelector((state) => state.login);

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(name ? false : true);
  const [error, setError] = useState(false);
  const [selectedResults, setSelectedResults] = useState(0);
  ///////////////////////////////////////////////////////
  // on load get the latest user data
  ///////////////////////////////////////////////////////
  const getPrivateUser = async () => {
    try {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const quizHistory = [];
        const qhQuery = await getDocs(
          collection(db, `users/${auth.currentUser.uid}/quizHistory`)
        );
        qhQuery.forEach((q) => {
          quizHistory.push({ id: q.id, ...q.data() });
        });
        dispatch(
          actions.setConfig({
            ...userSnapshot.data(),
            quizHistory,
          })
        );
        setLoading(false);
      } else {
        throw new Error("User not found");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initPage = () => {
    if (auth.currentUser) {
      getPrivateUser();
    }
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    initPage();
  }, []);
  useEffect(() => {
    initPage();
  }, [auth.currentUser]);

  const sortedQuizHistory = useMemo(
    () => sortHistory(quizHistory),
    [quizHistory]
  );

  ///////////////////////////////////////////////////////
  //
  ///////////////////////////////////////////////////////

  const myRef = useRef();

  const scrollLeft = () => {
    const current = myRef.current.scrollLeft;
    myRef.current.scrollTo(current - 300, 0);
  };
  const scrollRight = () => {
    const current = myRef.current.scrollLeft;
    myRef.current.scrollTo(current + 300, 0);
  };

  const handleQuizButton = (startNew) => {
    if (currentQuiz.position.join("") !== "budgeting0" && !startNew) {
      dispatch(actions.toggleCurrentQuiz(true));
    } else {
      dispatch(actions.toggleCurrentQuiz(false));
    }
    setRedirect(true);
  };

  const { View: ScoreLottie } = useLottie({
    loop: true,
    autoplay: true,
    animationData: Score,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  if (loading) {
    return <SplashScreen />;
  }

  if (redirect) {
    return <Navigate to="/quiz" />;
  }

  return (
    <>
      <Grid
        container
        sx={{
          p: 4,
          [theme.breakpoints.down("md")]: {
            p: 0,
          },
        }}
        p={4}
        textAlign="left"
      >
        <Grid item xs={12} md={8} p={4}>
          <Typography variant="h3" fontWeight="bold">
            Welcome back, {name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          p={4}
          sx={{
            borderRadius: 10,
            mx: "auto",
            background: "whitesmoke",
            my: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              p: 2,
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "flex-start",
              },
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {currentQuiz.position.join("") !== "budgeting0"
                ? `Continue where you left off: ${capitalizeFirstLetter(
                    currentQuiz.position[0]
                  )}, Question ${currentQuiz.position[1] + 1}`
                : `Start an assessment`}
            </Typography>
            <Button
              onClick={() => handleQuizButton(false)}
              variant="contained"
              sx={{
                fontWeight: "bold",
                textTransform: "none",
                textDecoration: "italic",
                borderRadius: 8,
                fontSize: "1.2em",
              }}
            >
              {currentQuiz.position.join("") !== "budgeting0"
                ? `Resume`
                : `Begin assessment`}
            </Button>
          </Box>
          {currentQuiz.position.join("") !== "budgeting0" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                p: 2,
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
              }}
            >
              <Typography variant="h5" fontWeight="bold">
                Or start assessment again
              </Typography>
              <Button
                onClick={() => handleQuizButton(true)}
                variant="contained"
                sx={{
                  fontWeight: "bold",
                  textTransform: "none",
                  textDecoration: "italic",
                  borderRadius: 8,
                  fontSize: "1.2em",
                }}
              >
                Start again
              </Button>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          p={4}
          sx={{
            borderRadius: 10,
            mx: "auto",
          }}
        >
          <Box sx={{ maxWidth: "90vw", width: 350, m: "auto" }}>
            {ScoreLottie}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          p={4}
          sx={{
            background: "whitesmoke",
            borderRadius: 10,
            mx: "auto",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            Assessment history
          </Typography>
          <Typography my={3}>
            Below, you will find details of the previous assessments that you
            have taken. Please select a date and you will see a copy of the
            final assessment report below.
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            Select your previous assessment
          </Typography>
          {!sortedQuizHistory.length ? (
            <Typography>
              You haven't completed any assessments yet, your results history
              will show up here when you do.
            </Typography>
          ) : (
            <Box sx={{ position: "relative", zIndex: "200" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  [theme.breakpoints.down("md")]: {
                    width: "25px",
                  },
                }}
                onClick={scrollLeft}
              >
                <ChevronLeft
                  sx={{
                    m: "auto",
                    fontSize: 75,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 30,
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: "0px",
                  height: "100%",
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  [theme.breakpoints.down("md")]: {
                    width: "25px",
                  },
                }}
                onClick={scrollRight}
              >
                <ChevronRight
                  sx={{
                    m: "auto",
                    fontSize: 75,
                    [theme.breakpoints.down("md")]: {
                      fontSize: 30,
                    },
                  }}
                />
              </Box>
              <Tooltip
                title={<h4>Scroll horizonally to view more</h4>}
                placement="bottom"
              >
                <Box
                  ref={myRef}
                  sx={{
                    overflowX: "scroll",
                    display: "flex",
                    flexDirection: "row",
                    p: 4,
                    width: "calc(  100% - 100px )",
                    mx: "auto",
                    [theme.breakpoints.down("md")]: {
                      width: "calc(  100% - 50px )",
                    },
                  }}
                >
                  {sortedQuizHistory.map((quiz, idx) => {
                    return (
                      <Box
                        sx={{ display: "flex", flexDirection: "row" }}
                        key={quiz.id}
                      >
                        <Button
                          onClick={() => setSelectedResults(idx)}
                          variant="outlined"
                          sx={{
                            minWidth: 300,
                            borderRadius: 10,
                            px: 2,
                            m: 2,
                            backgroundColor:
                              selectedResults === idx ? "white" : "none",
                            border: "none",
                            boxShadow:
                              selectedResults === idx
                                ? `0px 0px 20px 20px rgba(0,0,0,0.05)`
                                : "none",
                            [theme.breakpoints.down("md")]: {
                              minWidth: "200px",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            <Bubble score={quiz.overallGrade} size={50} />

                            <Typography variant="h6" fontWeight="bold">
                              {displayDate(quiz.date)}
                            </Typography>
                          </Box>
                        </Button>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Menu
                            theme={theme}
                            assessment={quiz}
                            reload={getPrivateUser}
                            name={name}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Tooltip>
            </Box>
          )}
        </Grid>
      </Grid>
      {sortedQuizHistory.length > 0 && (
        <>
          <Typography variant="h4" fontWeight="bold">
            Assessment date:{" "}
            {displayDate(sortedQuizHistory[selectedResults].date)}
          </Typography>
          <Results
            history={sortedQuizHistory[selectedResults].history}
            finalGrades={sortedQuizHistory[selectedResults].finalGrades}
            sections={sortedQuizHistory[selectedResults].sections}
            overall={sortedQuizHistory[selectedResults].overall}
            headers={sortedQuizHistory[selectedResults].headers}
            overallGrade={sortedQuizHistory[selectedResults].overallGrade}
            conditionals={sortedQuizHistory[selectedResults].conditionals}
            isLookback={true}
            completedSections={
              sortedQuizHistory[selectedResults].completedSections ||
              Object.keys(sortedQuizHistory[selectedResults].history).filter(
                (s) => sortedQuizHistory[selectedResults].history[s].length > 2
              )
            }
          />
        </>
      )}
    </>
  );
}
