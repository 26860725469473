import React, { useState, useEffect } from "react";
import { storage } from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";

export default function Logo({ companyName, width, useSecondary }) {
  const [src, setSrc] = useState("");
  const fileName = useSecondary ? "logo2.png" : "logo.png";
  const pathReference = ref(storage, `Companies/${companyName}/${fileName}`);

  const getImage = async () => {
    const url = await getDownloadURL(pathReference);
    setSrc(url);
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <>
      {src ? (
        <img
          src={src}
          style={{
            margin: "auto",
            width: width,
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
