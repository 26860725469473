import logo from "./logo.svg";
import "./App.css";

import Wrapper from "./views/quizView/Wrapper";
import InternalLoginRegister from "./views/internalLoginRegister/InternalLoginRegister";
import CompanyLandingPage from "./views/companyLandingPage/CompanyLandingPage";
import HomePage from "./views/homePage/HomePage";
import Account from "./views/account/Account";
import BespokeLandingPage from "./views/bespokeLoginScreen/bespokeLoginScreen";
import Logout from "./views/logout/Logout";
import Faq from "./views/Faq/index";
import MfaScreen from "./views/mfaScreen/index";

import { createTheme, ThemeProvider } from "@mui/material";
import { themeconfig } from "./ThemeConfigs";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./components/Layout";
import GenericRootPage from "./views/GenericRootPage/genericRootPage";

function App() {
  const organizationName = useSelector(
    (state) => state.login.config.organizationName
  );

  const theme = createTheme(themeconfig[organizationName]);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Layout showNav={organizationName}>
            <Routes>
              <Route exact path="/" element={<GenericRootPage />} />
              <Route path="/:companyName" element={<BespokeLandingPage />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/mfa" element={<MfaScreen />} />

              {/* add check for logged in user matches route */}
              {organizationName && (
                <>
                  <Route
                    path="/landing/:companyName"
                    element={<CompanyLandingPage />}
                  />
                  {/* <Route path="/auth" element={<InternalLoginRegister />} /> */}
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/quiz" element={<Wrapper />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/faq" element={<Faq />} />
                </>
              )}
            </Routes>
          </Layout>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
