import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: {
    displayName: null,
    organizationName: null,
  },
  isOnResultsPage: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    resetState: (state) => {
      state.config = {
        displayName: null,
        organizationName: null,
      };
    },
    setConfig: (state, action) => {
      state.config = { ...action.payload };
    },
    toggleCurrentQuiz: (state, action) => {
      state.config.useCurrentQuiz = action.payload;
    },
    toggleOnResultsPage: (state, action) => {
      state.isOnResultsPage = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const actions = loginSlice.actions;

export default loginSlice.reducer;
