import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { query, collection, where, getDocs } from "firebase/firestore";
import { Grid, Typography } from "@mui/material";

import { Navigate } from "react-router-dom";
import { cmsDb } from "../../firebase";

import SplashScreen from "../../components/SplashScreen";

export default function CompanyLandingPage() {
  const org = useSelector((state) => state.login.config.organizationName);
  const [content, setContent] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const getContent = async (o) => {
    const q = query(collection(cmsDb, "questionsets"), where("name", "==", o));
    const found = await getDocs(q);
    if (found.empty) {
      console.log("empty");
      setRedirect(true);
    }
    found.forEach((doc) => {
      const data = doc.data();
      if (data.faq) {
        setContent(data.faq);
      } else {
        setRedirect(true);
      }
    });
  };
  useEffect(() => {
    if (org && !content) {
      getContent(org);
    }
  }, [org]);

  if (redirect) {
    return <Navigate to="/home" />;
  }
  if (!content) {
    return <SplashScreen />;
  }

  return (
    <Grid
      container
      textAlign="left"
      p={2}
      sx={{ maxWidth: "1000px", margin: "auto", justifyContent: "center" }}
    >
      <Grid item xs={12}>
        <Typography variant="h2" fontWeight="bold" textAlign="center" p={4}>
          Frequently Asked Questions
        </Typography>
        <Typography fontWeight="bold" textAlign="center" p={2}>
          Please find below responses to the most commonly asked questions
        </Typography>
      </Grid>

      {content.map((qa) => {
        return (
          <>
            <Grid item xs={3} p={2}>
              <Typography fontWeight="bold">{qa.question}</Typography>
            </Grid>
            <Grid item xs={7} p={2}>
              <Typography sx={{ whiteSpace: "pre-line", textAlign: "left" }}>
                {qa.answer}
              </Typography>
            </Grid>
          </>
        );
      })}
    </Grid>
  );
}
