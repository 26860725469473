// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "@firebase/firestore";
import "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
let firebaseConfig = {
  apiKey: "AIzaSyCsuSl2YHdahYZ2rs5OtHP6qGMMAaK20A4",
  authDomain: "financial-fitness-18b9b.firebaseapp.com",
  databaseURL:
    "https://financial-fitness-18b9b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "financial-fitness-18b9b",
  storageBucket: "financial-fitness-18b9b.appspot.com",
  messagingSenderId: "574285561757",
  appId: "1:574285561757:web:760828b8d0a2530be77974",
};

if (process.env.REACT_APP_IS_TEST) {
  firebaseConfig = {
    apiKey: "AIzaSyDYfhQnsm5gqDE9090D8zynP9m7KP4REwE",
    authDomain: "financial-fitness-test.firebaseapp.com",
    projectId: "financial-fitness-test",
    storageBucket: "financial-fitness-test.appspot.com",
    messagingSenderId: "238079692442",
    appId: "1:238079692442:web:631a698c62f6b30bf49923",
  };
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

// QUIZ DATA
const cmsApp = initializeApp(
  {
    apiKey: "AIzaSyBtVBqx1jD50QhkBqV1Jb9A58zb7MtngiU",
    authDomain: "financial-fitness-cms.firebaseapp.com",
    projectId: "financial-fitness-cms",
    storageBucket: "financial-fitness-cms.appspot.com",
    messagingSenderId: "175949551885",
    appId: "1:175949551885:web:e331c4cb3783bcc5bc7844",
  },
  "cmsApp"
);
export const cmsDb = getFirestore(cmsApp);
