import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import styled from "styled-components";
import { updateModal } from "../actions";
import { useTheme } from "@mui/styles";

import Icon from "../../../components/Icon";

const GlimmerButton = styled.div`
  && {
    cursor: pointer;

    font-weight: bold;
    font-size: 18px;

    height: 150px;
    width: 200px;
    margin: 10px;

    display: inline-flex;
    position: relative;
    text-align: center;
    color: white;
    background-color: ${(p) =>
      p.theme.palette.primary.light || p.theme.palette.primary.main};
    border: 1px solid ${(p) => p.theme.palette.primary.main};
    border-radius: 20px;

    box-shadow: 0 0 0 0 transparent;
    transition: 0.2s ease-in;

    @media (max-width: 600px) {
      margin: 2px;
      height: 70px;
      min-width: 100%;
    }

    &:active {
      box-shadow: 0 0 0 0 transparent;
      transition: 0.2s ease-out;
    }

    @media (hover: hover) {
      :hover {
        background: ${(p) => p.theme.palette.warning.main};
        border: none;
      }
    }

    ${(p) => {
      return p.animate
        ? `
          &:before {
          animation: shine 9s ease;
          content: "";
          display: block;
          width: 0px;
          height: 100%;
          position: absolute;
          top: 0%;
          left: 0%;
          
          opacity: 0;
          background: white;
          box-shadow: 0 0 30px 3px white;
          transform: skewX(-30deg);
        }     
      `
        : "";
    }}

    p {
      margin: auto;
      padding: 10px;
    }
  }
`;

const AnswersContainer = styled(Grid)`
  transition: 0.5s;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    flex-direction: column !important;
    max-width: 95%;
    margin: auto;
  }
`;

const AnimDiv = styled.div`
  && {
    margin-top: 50px;
    padding-bottom: 20px;
    ${(p) => (p.animate ? `animation: flash 0.8s ease-out 1;` : "")}
  }
`;

export default function Question({
  position,
  sections,
  onAnswerClick,
  setHistory,
  history,
  setPosition,
  skips,
  setSkips,
  toggleModal,
  setModalInfo,
  conditionals,
  setConditionals,
  setOverallGrade,
  setFinalGrades,
  toggleResults,
  headers,
  setHeaders,
  completedSections,
  setCompletedSections,
  scoring,
}) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(false);
  }, [position]);

  const theme = useTheme();
  const s = sections[position[0]];
  const q = s.questions[position[1]];

  let title = s.title;
  if (position[0] === "homeowner" || position[0] === "renter") {
    title = "Household Spending";
  }

  return (
    <Grid container sx={{ maxWidth: "1000px", display: "block" }}>
      <Box
        sx={{
          p: 2,
        }}
      >
        <Icon section={position[0]} width="75px" />
      </Box>
      <Typography
        variant="h5"
        sx={{
          display: "block",
          width: "100%",
          textTransform: "uppercase",
          letterSpacing: "0.75em",
          fontFamily: "Arla-Thick",
          color: "primary.main",
          mt: 2,
        }}
      >
        {title}
      </Typography>
      <AnimDiv animate={animate}>
        <Typography variant="h5" fontWeight="bold" px={2} pb={2}>
          {q.question}
        </Typography>
        <Typography fontSize="1.1em" fontStyle="italic" px={2}>
          {q.hint}
        </Typography>
        <AnswersContainer item xs={12} pt={2}>
          {q.answers.map((a, idx) => {
            return (
              <GlimmerButton
                idx={idx}
                theme={theme}
                animate={!animate}
                onClick={() => {
                  setAnimate(true);

                  setTimeout(() => {
                    onAnswerClick(
                      position,
                      a,
                      setHistory,
                      history,
                      setPosition,
                      skips,
                      setSkips,
                      toggleModal,
                      updateModal,
                      setModalInfo,
                      sections,
                      conditionals,
                      setConditionals,
                      setOverallGrade,
                      setFinalGrades,
                      toggleResults,
                      headers,
                      setHeaders,
                      completedSections,
                      setCompletedSections,
                      scoring
                    );
                  }, 600);
                }}
              >
                <p>{a.a}</p>
              </GlimmerButton>
            );
          })}
        </AnswersContainer>
      </AnimDiv>
    </Grid>
  );
}
