import React, { useEffect, useState } from "react";
import { useParams, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";

import {
  Grid,
  Dialog,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@mui/material";

import { auth, db } from "../../firebase";
import { fetchUser } from "../bespokeLoginScreen/actions";

import axios from "axios";
import { Add } from "@mui/icons-material";

import { createDecipheriv } from "crypto";

export default function MfaScreen() {
  const dispatch = useDispatch();

  const [accordian, setAccordian] = useState(false);
  const [error, setError] = useState(null);

  const { o, new: hasNoNumber, creds } = qs.parse(window.location.search);

  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [requestCodeStatus, setRequestCodeStatus] = useState(null);
  const [confirmCodeStatus, setConfirmCodeStatus] = useState(null);
  const [authStatus, setAuthStatus] = useState(null);

  const [redirect, setRedirect] = useState(null);

  const invalidNumber = number.length != 11 || !number.startsWith("07");

  const decryptCreds = () => {
    try {
      const key = localStorage.getItem("key");

      return {
        email: creds,
        password: atob(key),
      };
    } catch (e) {
      console.log(e);
    }
  };

  const requestCode = async () => {
    try {
      setRequestCodeStatus("loading");

      const url = new URL(
        "https://europe-west1-financial-fitness-18b9b.cloudfunctions.net/mfa"
      );

      const { email } = decryptCreds();
      url.searchParams.set("email", email);
      url.searchParams.set("number", "+44" + number.slice(1));
      const res = await axios.get(url.toString());
      if (res.status === 204) {
        //let them in and bypass mfa
        setRequestCodeStatus("done");
        setConfirmCodeStatus("success");
      } else if (res.status === 200) {
        // prompt em
        setRequestCodeStatus("success");
      } else {
        throw new Error("Bad response");
      }
    } catch (e) {
      setError("Error requesting OTP");
      setRequestCodeStatus("error");
    }
  };

  const sendCode = async () => {
    if (code.length !== 6) {
      return;
    }

    try {
      const { email } = decryptCreds();

      setRequestCodeStatus("done");
      setConfirmCodeStatus("loading");
      const res = await axios.get(
        "https://europe-west1-financial-fitness-18b9b.cloudfunctions.net/postMfaCode" +
          `?code=${code}&email=${email}`
      );
      if (res.status === 200) {
        // prompt em
        setConfirmCodeStatus("success");
        authenticate();
      } else {
        throw new Error("Bad response");
      }
    } catch (e) {
      setConfirmCodeStatus("error");
      setError("Error requesting OTP");
    }
  };

  const authenticate = async () => {
    try {
      const { email, password } = decryptCreds();

      setConfirmCodeStatus("done");
      setAuthStatus("loading");
      const { user } = await signInWithEmailAndPassword(auth, email, password);

      await fetchUser(dispatch, user, o);
      setRedirect(`/landing/${o}`);
    } catch (e) {
      console.log(e);
      setAuthStatus("error");
      setError("Auth error");
      throw e;
    }
  };

  useEffect(async () => {
    console.log(hasNoNumber);
    if (+hasNoNumber == 0) {
      await requestCode();
    }
  }, [hasNoNumber]);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  return (
    <Grid>
      <Dialog open>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            textAlign: "center",
          }}
        >
          {requestCodeStatus === null && (
            <>
              {+hasNoNumber != 1 && (
                <Typography p={2}>
                  Sending your SMS verification code.
                </Typography>
              )}
              {+hasNoNumber == 1 && (
                <>
                  <Typography p={2}>
                    You have not previously registered a phone number, you will
                    need to do this now. Please enter your number below.
                  </Typography>
                  <TextField
                    label="mobile number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    error={invalidNumber ? number.length > 1 : false}
                    helperText={
                      invalidNumber && number.length > 1 ? "Invalid number" : ""
                    }
                  />
                  <br />
                </>
              )}
              <Button
                variant="contained"
                onClick={requestCode}
                disabled={+hasNoNumber === 1 ? invalidNumber : false}
              >
                SEND SMS
              </Button>
            </>
          )}

          {requestCodeStatus === "loading" && <CircularProgress />}

          {requestCodeStatus === "success" && (
            <>
              <Typography p={2}>
                Your code has been sent. Please enter your SMS authentication
                code
              </Typography>
              <TextField
                label="6 Digit Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <br />
              <Button variant="contained" onClick={sendCode}>
                Confirm Code
              </Button>
            </>
          )}

          {confirmCodeStatus === "loading" && <CircularProgress />}

          {/* {confirmCodeStatus === "success" && (
            <>
              <Typography p={2}>Please enter your account password</Typography>
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
              <br />
              <Button variant="contained" onClick={authenticate}>
                Login
              </Button>
            </>
          )} */}

          {authStatus === "loading" && <CircularProgress />}

          {error && (
            <Alert severity="error">
              <Typography>{error}</Typography>
            </Alert>
          )}

          <Accordion
            expanded={accordian}
            onChange={() => setAccordian(!accordian)}
          >
            <AccordionSummary expandIcon={<Add />}>
              <Typography
                sx={{
                  width: "200px",
                  margin: "auto",
                  textAlign: "center",
                  fontSize: "10px",
                }}
              >
                Having trouble?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                If you're having trouble accessing your account, or need to use
                an alternative phone number to the one you gave us when you
                registered, please send us an email, from your registered email
                address, to{" "}
                <a href="mailto:hello@people-tech.co.uk">
                  hello@people-tech.co.uk
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Dialog>
    </Grid>

    // <Grid>
    //   <Dialog open>
    //     <Box
    //       sx={{
    //         padding: 2,
    //         minWidth: "300px",
    //       }}
    //     >
    //       <Typography
    //         sx={{
    //           textAlign: "center",
    //           fontWeight: "bold",
    //           fontSize: "1.1em",
    //           padding: 2,
    //         }}
    //       >
    //         {mode === "create"
    //           ? "To help protect your account, we have added additional security. To login, you need to enter a code which will be sent to your phone. Please add your number below to continue."
    //           : "Confirm it's you "}
    //       </Typography>
    //       {!verificationId && (
    //         <Box
    //           sx={{
    //             display: "flex",
    //             flexDirection: "column",
    //             justifyContent: "center",
    //           }}
    //         >
    //           {mode === "create" ? (
    //             <TextField
    //               label="Mobile number"
    //               placeholder="Mobile number"
    //               value={number}
    //               onChange={(e) => setNumber(e.target.value)}
    //             />
    //           ) : (
    //             <>
    //               <TextField
    //                 value={window.resolver.hints[0].phoneNumber}
    //                 disabled
    //               />
    //               <Accordion
    //                 expanded={accordian}
    //                 onChange={() => setAccordian(!accordian)}
    //               >
    //                 <AccordionSummary expandIcon={<Add />}>
    //                   <Typography
    //                     sx={{
    //                       width: "200px",
    //                       margin: "auto",
    //                       textAlign: "center",
    //                       fontSize: "10px",
    //                     }}
    //                   >
    //                     Having trouble?
    //                   </Typography>
    //                 </AccordionSummary>
    //                 <AccordionDetails>
    //                   <Typography>
    //                     If you're having trouble accessing your account or need
    //                     to use an alternative phone number, please send us an
    //                     email, from your registered email address, to{" "}
    //                     {o === "demo" ? (
    //                       <a href="mailto:widgets@people-tech.co.uk">
    //                         widgets@people-tech.co.uk
    //                       </a>
    //                     ) : (
    //                       <a href="mailto:hello@helpandadvice.co.uk">
    //                         hello@helpandadvice.co.uk
    //                       </a>
    //                     )}
    //                     .
    //                   </Typography>
    //                 </AccordionDetails>
    //               </Accordion>
    //             </>
    //           )}
    //           <br />
    //           {!hideSMSButton && (
    //             <Button
    //               variant="contained"
    //               color="primary"
    //               disabled={mode === "create" && !number}
    //               onClick={requestCode}
    //             >
    //               Send SMS
    //             </Button>
    //           )}
    //           {hideSMSButton && mode === "verify" && (
    //             <Box>
    //               <Typography>
    //                 You will now receive a 6 digit code to your registered phone
    //                 number
    //               </Typography>
    //             </Box>
    //           )}
    //         </Box>
    //       )}
    //       {verificationId && (
    //         <Box
    //           sx={{
    //             display: "flex",
    //             flexDirection: "column",
    //             justifyContent: "center",
    //           }}
    //         >
    //           <Alert severity="success">SMS Sent</Alert>
    //           {error !== null && <Alert severity="error">{error}</Alert>}
    //           <br />
    //           <TextField
    //             placeholder="Verification code"
    //             value={code}
    //             onChange={(e) => setCode(e.target.value)}
    //           />
    //           <br />
    //           <Button
    //             variant="contained"
    //             color="primary"
    //             disabled={!code}
    //             onClick={confirmCode}
    //           >
    //             Confirm
    //           </Button>
    //         </Box>
    //       )}
    //     </Box>
    //   </Dialog>
    // </Grid>
  );
}
