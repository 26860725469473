import { db, auth, cmsDb } from "../../firebase";
import {
  doc,
  getDoc,
  setDoc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import {
  signOut,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { actions } from "../logInScreen/slice";
import axios from "axios";
import { config } from "../../config";

export const fetchUser = async (dispatch, user, organizationName) => {
  const userRef = doc(db, "users", user.uid);
  const userSnapshot = await getDoc(userRef);
  if (userSnapshot.exists()) {
    const data = userSnapshot.data();
    // check user has access
    if (data.organizationName === organizationName) {
      const quizHistory = [];
      const qhQuery = await getDocs(
        collection(db, `users/${user.uid}/quizHistory`)
      );
      qhQuery.forEach((q) => {
        quizHistory.push(q.data());
      });
      dispatch(
        actions.setConfig({
          ...userSnapshot.data(),
          quizHistory,
        })
      );
    } else {
      alert("bespoke login component - handle unauthenticated user");
      await signOut(auth);
      throw new Error(`user no longer belongs to org`);
      // do some db changes and then log them in
    }
  }
};

export const registerUser = async (fields, organizationName) => {
  const userCredentials = await createUserWithEmailAndPassword(
    auth,
    fields.useCompanyAsPrimary.value
      ? fields.companyEmail.value
      : fields.personalEmail.value,
    fields.password.value
  );

  const { user } = userCredentials;
  const { uid } = user;

  await sendEmailVerification(auth.currentUser);

  const userData = {
    isMfaUser: true,
    secondaryEmail: fields.useCompanyAsPrimary.value
      ? fields.personalEmail.value
      : fields.companyEmail.value,
    name: fields.firstName.value,
    organizationName,
    displayName: {
      arla: "Arla",
      everton: "Everton Football Club",
      helpandadvice: "Help and Advice",
      adidas: "adidas",
      demo: "Widgets Ltd",
      liverpool: "Liverpool FC",
    }[organizationName],
    currentQuiz: {
      position: ["budgeting", 0],
    },
  };

  const additionalFields = Object.keys(fields).reduce((acc, f) => {
    const newAcc = { ...acc };
    if (fields[f].card !== 0) {
      newAcc[f] = fields[f].value;
    }
    return newAcc;
  }, userData);

  await setDoc(doc(db, "users", uid), additionalFields);

  if (!process.env.REACT_APP_IS_TEST) {
    await axios({
      method: "POST",
      url: "/.netlify/functions/registerContact",
      // url: "https://upbeat-montalcini-34e93f.netlify.app/.netlify/functions/updateSheet",
      data: JSON.stringify({
        org: organizationName,
        email: fields.useCompanyAsPrimary.value
          ? fields.companyEmail.value
          : fields.personalEmail.value,
        firstName: fields.firstName.value,
      }),
    });

    const token = await auth.currentUser.getIdToken();

    await axios({
      method: "POST",
      url: `${config.apiUrl}/user`,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        org: organizationName,
        email: fields.useCompanyAsPrimary.value
          ? fields.companyEmail.value
          : fields.personalEmail.value,
        email2: !fields.useCompanyAsPrimary.value
          ? fields.companyEmail.value
          : fields.personalEmail.value,
        questions: Object.keys(fields)
          .sort()
          .reduce((acc, key) => {
            if (fields[key].card !== 0) {
              return [...acc, fields[key].value || "-"];
            }
            return acc;
          }, []),
      }),
    });
  }
  return user;
};

export const fetchCaptureFields = async (
  fields,
  setFields,
  organizationName
) => {
  const q = query(
    collection(cmsDb, "questionsets"),
    where("name", "==", organizationName)
  );
  const found = await getDocs(q);
  if (found.empty) {
    throw new Error("No user capture found");
  }
  found.forEach((doc) => {
    const { capturedUserData } = doc.data();
    const newFields = {};
    for (const f of capturedUserData) {
      newFields[f.label] = {
        card: 1,
        value: "",
        label: f.label,
        ...(f.type === "dropdown" && { options: [...f.options] }),
      };
    }
    setFields({
      ...fields,
      ...newFields,
    });
  });
};
