import React from "react";
import styled from "styled-components";
import { Box, CircularProgress, Typography } from "@mui/material";

const colours = Object.freeze({
  a: "#38761d",
  b: "#6aa74f",
  c: "#f1c231",
  d: "#bf9001",
  e: "#cc0100",
  f: "#990001",
  "?": "lightgray",
});

const BubbleHolder = styled.div`
  // margin: 0 ${(p) => p.margin || 10}px;
  display: inline-block;
  vertical-align: top;
  max-width: ${(p) => p.maxWidth};
  max-height: ${(p) => 1.1 * p.maxWidth};
  position: relative;
  ${(p) =>
    p.spin &&
    `
      animation: animName 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;`}
`;

const B = styled.div`
  && {
    width: ${(p) => p.size}px;
    height: ${(p) => p.size}px;
    display: flex;
    justify-content: center;
    // background: ${(p) => colours[p.score]};
    background: whitesmoke;
    border-radius: 50%;
    margin: 5px auto;
    position: absolute; 
    left: 0; 
    top: 0; 

    @keyframes pulse-ring {
      0% {
        transform: scale(0.33);
      }
      80%,
      100% {
        opacity: 0;
        transform: scale(1.5);
      }
    }

  &:before {
      content: "";
      position: absolute;
      display: ${(p) => (p.pulse ? "block" : "none")};
      width: ${(p) => p.size}px;
      height: ${(p) => p.size}px;
      border-radius: 50%;
      background: ${(p) => colours[p.score]};
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
      z-index: -1;
    }
`;

const P = styled.p`
  text-align: center;
  line-height: ${(p) => p.size / 2}px;
  font-size: ${(p) => p.size / 3}px;
  font-weight: bold;
  margin: auto;
  text-transform: uppercase;
`;

export default function Bubble({
  score,
  size,
  title,
  margin,
  maxWidth,
  spin,
  pulse,
}) {
  const progress = {
    a: 100,
    b: 90,
    c: 75,
    d: 65,
    e: 55,
    f: 40,
  }[score];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'flex-start',
        m: 0

      }}
    >
      <BubbleHolder spin={spin} margin={margin} maxWidth={maxWidth}>
        <B size={size} score={score} pulse={pulse}>
          <P size={size}> {score}</P>
        </B>
        <Box
          sx={{
            color: colours[score] || "whitesmoke",
            width: '100%'
          }}
        >
          <CircularProgress
            color="inherit"
            sx={{
              marginTop: "5px",
            }}
            thickness={5}
            size={size}
            variant="determinate"
            value={progress || 100}
          />
        </Box>
      </BubbleHolder>

      {title && (
        <Typography
          sx={{
            fontWeight: "bold",
            width: "100%",
            margin: "auto",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      )}
    </Box>
  );
}
