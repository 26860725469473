import React from "react";
import { useLottie } from "lottie-react";
import { Grid, Typography, Box, useTheme } from "@mui/material";
import LoginPage from "../../assets/lotties/loginPage.json";

import financialEducationLogo from "../../assets/images/financialeducationlogo.png";

export default function GenericRootPage() {
  const theme = useTheme();

  const options = {
    loop: true,
    autoplay: true,
    animationData: LoginPage,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { View } = useLottie(options, { width: "50%", margin: "auto" });

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          borderRadius: 3,
          p: 2,
        }}
      >
        <img src={financialEducationLogo} width="100px" />
        <Box fullWidth sx={{ maxWidth: 500, m: "auto" }}>
          {View}
        </Box>
        <Typography>
          To log on to the financial fitness assessment for your organisation,
          you will need to use the link that was provided to you. Alternatively,
          you can try by adding the name of your company to the end of the URL
          for this page, eg https://app.financialapp.co.uk/
          <b style={{ color: theme.palette.primary.main }}>your companyname</b>
        </Typography>
      </Grid>
    </Grid>
  );
}
