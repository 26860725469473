import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import styled from "styled-components";

const StyledTextField = styled(TextField)`
  && {
    label {
      color: ${(p) => p.themeconfig.palette.primary.main} !important;
    }
    input {
      color: ${(p) => p.themeconfig.palette.primary.main} !important;
    }
    * {
      border-color: ${(p) => p.themeconfig.palette.primary.main} !important;
    }
  }
`;

export default function Create({
  fields,
  themeconfig,
  updateField,
  setCreateCardNumber,
  createCardNumber,
  showNext1,
  showNext2,
  createUser,
  createUserLoading,
}) {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "primary.main",
        borderRadius: 3,
        p: 2,
      }}
    >
      <Grid item xs={12}>
        <Typography color="white" variant="h2">
          Create an account*
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ background: "white", borderRadius: 3, p: 2 }}>
        <Typography m={2}>Step {createCardNumber + 1} of 2</Typography>
        {Object.keys(fields).map((key) => {
          if (fields[key].card === createCardNumber) {
            return (
              <TextField
                sx={{
                  mb: 2,
                }}
                select={fields[key].options}
                key={key}
                themeconfig={themeconfig}
                fullWidth
                label={fields[key].label}
                value={fields[key].value}
                onChange={(e) => updateField(key, e.target.value)}
                type={fields[key].type ? fields[key].type : "text"}
                helperText={
                  fields[key].type === "password"
                    ? "must be at least 6 characters"
                    : ""
                }
              >
                {fields[key].options &&
                  fields[key].options.map((o) => {
                    return <MenuItem value={o}>{o}</MenuItem>;
                  })}
              </TextField>
            );
          }
        })}
        <Typography p={4} fontSize="0.9em">
          * Please note that your individual results are not shared with the
          Club. Aggregated results are produced to help identity which areas the
          Club should focus on when providing support to Staff with their
          financial wellbeing. You will also consent to a copy of your
          assessment being emailed to you and to receive follow up emails that
          have additional tips and guidance on ways you can improve your
          financial wellbeing. You will however be able to unsubscribe at any
          time.
        </Typography>
        {createCardNumber === 0 && (
          <Button
            disabled={!showNext1}
            sx={{
              borderRadius: 20,
              fontWeight: "bold",
              textTransform: "none",
            }}
            p={2}
            variant="outlined"
            onClick={() => setCreateCardNumber(1)}
          >
            Next
          </Button>
        )}
        {showNext2 && !createUserLoading && (
          <Button
            sx={{
              borderRadius: 20,
              fontWeight: "bold",
              textTransform: "none",
            }}
            p={2}
            variant="outlined"
            onClick={createUser}
          >
            Create Account
          </Button>
        )}
        {createUserLoading && <CircularProgress size={100} />}
      </Grid>
    </Grid>
  );
}
