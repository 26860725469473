import { v4 as uuid } from "uuid";

export const makeHTML = (results, name, accentColor) => {
  return `
    <html>
      <body>
            <table style='width: 100%;'>
                <tr style='width: 100%;'>
                </tr>
                <tr style='width: 100%;'>
                  <td style='width: 100%;'>
                    <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">
                      Hi ${name}
                    </p>
                    <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">
                      Thank you for taking the time to complete your financial fitness report.
                      Below you will find a copy of the report that was produced when you
                      completed the assessment.
                    </p>
                    <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">
                      Please do have a read through it and find some time to follow up on the
                      actions that are suggested below.
                    </p>
                  </td>
                </tr>
                <tr style='width: 100%;'>
                </tr>
                <tr style='width: 100%;'>
                  <td style='width: 100%; display: flex; align-items: center;'>
                    <div style='width: 80px; line-height: 80px; height: 80px; text-align: center; border-radius: 50%; background-color: ${
                      colors[results.overallGrade]
                    }; color: white; font-weight: bold; font-size: 50px;'>
                      ${results.overallGrade.toUpperCase()}
                    </div>
                    <h2 style='margin: auto 10px; text-align: left;'>
                      Your overall score for this assessment
                    </h2>
                  </td>
                </tr>
                <tr style='width='100%;' >
                  <td>
                    <p style='font-size: 1.1em; white-space: pre-line;'>
                      ${results.overall.scoring[results.overallGrade]}
                    </p>
                  </td>
                </tr>
                <tr>
                  <th style='text-align: center; font-size: 2em;'>
                    A breakdown of your individual results
                  </th>
                </tr>
                ${Object.keys(results.finalGrades)
                  .sort((a, b) => {
                    return results.finalGrades[a] > results.finalGrades[b]
                      ? -1
                      : 0;
                  })
                  .map((s) => {
                    return `
                            <tr style='width: 100%;'>
                              <td>
                                <img src='${images[s]}' style='width: 100%;'/>
                              </td>
                            </tr>
                            <tr style='width: 100%;'>
                              <td style='width: 100%; display: flex; align-items: center;'>
                                <div style='width: 80px; line-height: 80px; height: 80px; text-align: center; border-radius: 50%; background-color: ${
                                  colors[results.finalGrades[s]]
                                }; color: white; font-weight: bold; font-size: 50px;'>
                                  ${results.finalGrades[s].toUpperCase()}
                                </div>
                                <h2 style='margin: auto 10px;'>
                                  Your overall score for ${
                                    results.sections[s].title
                                  }
                                </h2>
                              </td>
                            </tr>
                            <tr style='width: 100%;'>
                              <td style='width: 100%;'>
                                <p style='font-size: 1.1em; white-space: pre-line;'>
                                  ${
                                    results.sections[s].scoring[
                                      results.finalGrades[s]
                                    ]
                                  }
                                </p>
                              </td>
                            </tr>
                            ${results.history[s]
                              .filter((r) => r.comment.length)
                              .map((r) => {
                                return `
                                    <tr>
                                      <td>
                                        <h2 style='color: ${accentColor}; display: block;'>${
                                  r.heading
                                }</h2>
                                        <p style='font-size: 1.1em; white-space: pre-line; display: block;'>${r.comment.replace(
                                          /\n/g,
                                          "<br/>"
                                        )}</p>
                                        ${
                                          r.link.title
                                            ? `
                                            <p style='font-size: 1.1em; white-space: pre-line; display: block;'>
                                              <b style='color: red; font-size: 1.1em;'>Learn more - </b><a style='font-size: 1.1em;' target="_blank"  href='${r.link.url}' target='_blank'> ${r.link.title}</a>
                                            </p>
                                            `
                                            : ""
                                        }
                                      </td>
                                    </tr>
                                  `;
                              })
                              .join("")}
                        `;
                  })
                  .join("")}
          <tr style='width: 100%;'>
            <td>
                <h2>
                    What happens next
                </h2>
                <p style='font-size: 1.1em; white-space: pre-line;'>
                    Over the course of the next few weeks and months you will be sent a series of emails on the topics covered in this report.  The emails will provide guidance and tips on actions that you can take that will help improve your personal financial wellbeing.

                    Also, remember that you can take the assessment any time you wish.   You can log in to your account and you will be able to see copies of your past assessments, so you can compare how you are doing over time. 
                </p>
            </td>
          </tr>
        </table>
      </body>
    </html>`;
};

const colors = Object.freeze({
  a: "#38761d",
  b: "#6aa74f",
  c: "#f1c231",
  d: "#bf9001",
  e: "#cc0100",
  f: "#990001",
});

const images = Object.freeze({
  budgeting: `https://firebasestorage.googleapis.com/v0/b/financial-fitness-18b9b.appspot.com/o/publicResources%2FemailImages%2F1%20-%20Budgeting%20(1).png?alt=media&token=56c59be4-0c28-4cb1-a51f-47fdf9233651`,
  debts: `https://firebasestorage.googleapis.com/v0/b/financial-fitness-18b9b.appspot.com/o/publicResources%2FemailImages%2F2%20-%20Debt%20Management%20(2).png?alt=media&token=1a15bda3-1b2a-47a4-be80-fcae58e136fb`,
  pay: `https://firebasestorage.googleapis.com/v0/b/financial-fitness-18b9b.appspot.com/o/publicResources%2FemailImages%2F3%20-%20Pay%20_%20Allowances%20(1).png?alt=media&token=35216ea9-10b9-479f-a454-fc9c3e8f18c5`,
  homeowner: `https://firebasestorage.googleapis.com/v0/b/financial-fitness-18b9b.appspot.com/o/publicResources%2FemailImages%2F4%20-%20Household%20spending.png?alt=media&token=ba91573e-77de-4473-8afa-204b8231dea0`,
  renter: `https://firebasestorage.googleapis.com/v0/b/financial-fitness-18b9b.appspot.com/o/publicResources%2FemailImages%2F4%20-%20Household%20spending.png?alt=media&token=ba91573e-77de-4473-8afa-204b8231dea0`,
  pensions: `https://firebasestorage.googleapis.com/v0/b/financial-fitness-18b9b.appspot.com/o/publicResources%2FemailImages%2F6%20-%20Pensions%20_%20Savings%20(1).png?alt=media&token=a7b0476d-0449-4215-a406-98406c3611c1`,
  health: `https://firebasestorage.googleapis.com/v0/b/financial-fitness-18b9b.appspot.com/o/publicResources%2FemailImages%2F5%20-%20Health%20Protection%20(2).png?alt=media&token=5272684c-fa88-4bad-a649-b4e1736a42a0`,
  planning: `https://firebasestorage.googleapis.com/v0/b/financial-fitness-18b9b.appspot.com/o/publicResources%2FemailImages%2F7%20-%20Later%20Life%20Planning%20(1).png?alt=media&token=00f63fcb-8cfc-4939-8718-7c759632c0f3`,
});

export const makeResultUpdateBody = (
  uid,
  history,
  finalGrades,
  sections,
  overallGrade,
  userInfo
) => {
  const userInfoArray = buildUserInfo(userInfo);

  const body = {
    sessionUuid: uuid(),
    question1: userInfoArray[0],
    question2: userInfoArray[2],
    question3: userInfoArray[3],
    question4: userInfoArray[4],
    question5: userInfoArray[5],
    question6: userInfoArray[6],
    overallGrade,
    budgetingGrade: finalGrades.budgeting || "",
    debtsGrade: finalGrades.debts || "",
    payGrade: finalGrades.pay || "",
    homeGrade: finalGrades.homeowner || finalGrades.renter || "",
    healthGrade: finalGrades.health || "",
    pensionsGrade: finalGrades.pensions || "",
    planningGrade: finalGrades.planning || "",
  };

  const answers = [];

  for (const s of Object.keys(history)) {
    const sect = history[s];
    if (sect.length > 2) {
      for (const [, response] of sect.entries()) {
        const questionId = `${sections[s].order + 1}.${response.id}`;
        const { tag, question, ignoreScoring, shortForm } =
          sections[s].questions[response.id];
        answers.push({
          questionId: questionId,
          response: response.a,
          section: s,
          subSection: tag,
          question: question,
          short: shortForm,
          score: response.score,
          scoredQuestion: ignoreScoring ? 0 : 1,
        });
      }
    }
  }

  body.answers = answers;
  return body;
};

const buildUserInfo = (userInfo) => {
  const cleaned = { ...userInfo };
  delete cleaned.secondaryEmail;
  delete cleaned.displayName;
  delete cleaned.currentQuiz;
  delete cleaned.organizationName;
  delete cleaned.name;
  delete cleaned.quizHistory;
  delete cleaned.useCurrentQuiz;
  return Object.keys(cleaned)
    .sort()
    .map((field) => cleaned[field]);
};
