import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import QuizView from "./QuizView";

import { cmsDb } from "../../firebase";
import { query, collection, where, getDocs } from "firebase/firestore";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import SplashScreen from "../../components/SplashScreen";

export default function Wrapper() {
  const [config, setConfig] = useState();
  const [sections, setSections] = useState();
  const [overall, setOverall] = useState();
  const [scoring, setScoring] = useState();

  const org =
    useSelector((state) => state.login.config.organizationName) || "everton";

  const getQuiz = async (org) => {
    const q = query(
      collection(cmsDb, "questionsets"),
      where("name", "==", org)
    );
    const found = await getDocs(q);
    found.forEach((f) => {
      setConfig(f.data());
    });
  };

  useEffect(() => {
    getQuiz(org);
  }, [org]);

  useEffect(() => {
    if (config) {
      const { questionData, overall, scoring } = config;
      const ns = { ...questionData };
      for (const s of Object.keys(ns)) {
        ns[s].title = ns[s].title.split("-")[1];
      }
      setSections(ns);
      setOverall(overall);
      setScoring(scoring);
    }
  }, [config]);

  if (!sections && !overall) {
    return <SplashScreen />;
  }
  return <QuizView scoring={scoring} sections={sections} overall={overall} />;
}
