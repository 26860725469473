import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Button } from "@mui/material";

import { themeconfig as tc } from "../../ThemeConfigs";
// import HeaderBubbles from "./components/HeaderBubbles";
// import SideBubbles from "./components/SideBubbles";
import SideBubblesVertical from "./components/SideBubblesVertical";
import Question from "./components/Question";
import Modal from "./components/Modal";
import ProgressBar from "./components/ProgressBar";
import Results from "./components/Results";

import { auth, db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";

import { actions as globalActions } from "../logInScreen/slice";

import * as actions from "./actions";
import HeaderBubbles from "./components/HeaderBubbles";

export default function QuizView({ sections, overall, scoring }) {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.login.config);
  const { organizationName, useCurrentQuiz } = config;
  const themeconfig = tc[organizationName];

  const currentUserUID = auth.currentUser.uid;

  const [width, setWidth] = useState(0);

  const [completedSections, setCompletedSections] = useState([]);
  const [position, setPosition] = useState(["budgeting", 0]);
  const [overallGrade, setOverallGrade] = useState(0);
  const [finalGrades, setFinalGrades] = useState({});
  const [skips, setSkips] = useState([]);
  const [showModal, toggleModal] = useState(false);
  const [showResults, toggleResults] = useState(false);
  const [conditionals, setConditionals] = useState({});
  const [modalInfo, setModalInfo] = useState({
    key: "",
    image: "",
    title: "",
    grade: "",
    subheading: "",
    next: "",
  });

  const hdrs = {};
  Object.keys(sections).forEach((key) => {
    hdrs[key] = {
      order: sections[key].order,
      title: sections[key].title,
      grade: "",
    };
  });
  const [headers, setHeaders] = useState(hdrs);

  const h = Object.keys(sections).reduce((a, s) => {
    return { ...a, [s]: [] };
  }, {});
  const [history, setHistory] = useState(h);

  const updateCurrentQuiz = async () => {
    try {
      const userRef = doc(db, "users", currentUserUID);
      await updateDoc(userRef, {
        currentQuiz: {
          history,
          skips,
          finalGrades,
          overallGrade,
          headers,
          conditionals,
          position,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    updateCurrentQuiz();
  }, [
    history,
    skips,
    finalGrades,
    overallGrade,
    headers,
    conditionals,
    position,
  ]);

  useEffect(() => {
    if (useCurrentQuiz) {
      setHistory(config.currentQuiz.history);
      setSkips(config.currentQuiz.skips);
      setFinalGrades(config.currentQuiz.finalGrades);
      setOverallGrade(config.currentQuiz.overallGrade);
      setHeaders(config.currentQuiz.headers);
      setConditionals(config.currentQuiz.conditionals);
      setPosition(config.currentQuiz.position);
    }

    const listen = () => setWidth(window.innerWidth);

    window.addEventListener("resize", listen);
    listen();

    return () => window.removeEventListener("resize", listen);
  }, []);

  useEffect(() => {
    dispatch(globalActions.toggleOnResultsPage(showResults));
  }, [showResults]);

  if (showResults) {
    const fg = completedSections.reduce((acc, sect) => {
      return {
        ...acc,
        [sect]: headers[sect].grade,
      };
    }, {});
    return (
      <Results
        overall={overall}
        history={history}
        sections={sections}
        finalGrades={fg}
        overallGrade={overallGrade}
        headers={headers}
        conditionals={conditionals}
        completedSections={completedSections}
      />
    );
  }

  if (!themeconfig) {
    return <h1>loading</h1>;
  }

  return (
    <Grid container justifyContent={"center"}>
      <Modal
        endQuiz={() => {
          actions.endQuiz(
            history,
            sections,
            setOverallGrade,
            setFinalGrades,
            toggleResults,
            skips,
            completedSections,
            scoring
          );
        }}
        modalInfo={modalInfo}
        show={showModal}
        toggleModal={toggleModal}
        headers={headers}
        setHeaders={setHeaders}
      />
      {width > 900 && (
        <Grid
          xs={12}
          md={3}
          item
          margin={0}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <SideBubblesVertical
            title="Your Scores"
            setPosition={setPosition}
            setHistory={setHistory}
            history={history}
            clickable
            headers={headers}
            conditionals={conditionals}
            modalInfo={modalInfo}
            endQuiz={() => {
              setHistory({ ...history, [position[0]]: [] });
              actions.endQuiz(
                { ...history, [position[0]]: [] },
                sections,
                setOverallGrade,
                setFinalGrades,
                toggleResults,
                skips,
                completedSections,
                scoring
              );
            }}
            setHeaders={setHeaders}
          />
        </Grid>
      )}

      <Grid
        xs={12}
        md={width > 900 ? 8 : 12}
        // sx={{
        //   maxWidth: "500px !important",
        // }}
        item
        margin={0}
        marginTop={width > 900 ? 5 : 0}
      >
        <br></br>
        <ProgressBar
          position={position}
          sections={sections}
          themeconfig={themeconfig}
        />
        <Question
          position={position}
          setPosition={setPosition}
          onAnswerClick={actions.onAnswerClick}
          sections={sections}
          history={history}
          setHistory={setHistory}
          skips={skips}
          setSkips={setSkips}
          toggleModal={toggleModal}
          setModalInfo={setModalInfo}
          conditionals={conditionals}
          setConditionals={setConditionals}
          setOverallGrade={setOverallGrade}
          setFinalGrades={setFinalGrades}
          toggleResults={toggleResults}
          headers={headers}
          setHeaders={setHeaders}
          themeconfig={themeconfig}
          completedSections={completedSections}
          setCompletedSections={setCompletedSections}
        />
        <Grid container sx={{ maxWidth: "1000px", display: "block" }}>
          <Button
            sx={{
              borderRadius: 20,
              fontWeight: "bold",
              textTransform: "none",
            }}
            variant="outlined"
            color="secondary"
            disabled={position[1] === 0}
            onClick={() =>
              actions.onBackButtonClick(
                history,
                setPosition,
                setHistory,
                position,
                skips,
                setSkips,
                headers,
                setHeaders
              )
            }
          >
            Back to last question
          </Button>
        </Grid>
      </Grid>
      {width < 900 && (
        <Grid xs={12} md={3} item margin={"20px 0"}>
          <SideBubblesVertical
            title={"Your scores"}
            setPosition={setPosition}
            setHistory={setHistory}
            history={history}
            clickable
            headers={headers}
            conditionals={conditionals}
            modalInfo={modalInfo}
            setHeaders={setHeaders}
            endQuiz={() => {
              setHistory({ ...history, [position[0]]: [] });
              actions.endQuiz(
                { ...history, [position[0]]: [] },
                sections,
                setOverallGrade,
                setFinalGrades,
                toggleResults,
                skips,
                completedSections,
                scoring
              );
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}
