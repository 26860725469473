import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLottie } from "lottie-react";
import { v4 } from "uuid";
import {
  doc,
  updateDoc,
  arrayUnion,
  collection,
  setDoc,
} from "firebase/firestore";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { config } from "../../../config";

import { db, auth } from "../../../firebase";

import { Grid, Typography, Box, Button } from "@mui/material";

import Banner from "../../../assets/images/banner.png";
import confetti from "../../../assets/lotties/confetti.json";

import HeaderBubbles from "./HeaderBubbles";
import Bubble from "../../../components/Bubble";
import Icon from "../../../components/Icon";
import Logo from "../../../components/Logo";
import {
  makeHTML,
  makeResultUpdateBody,
  makeSpreadsheetRows,
} from "../../homePage/utils";

const IF = styled.iframe`
  border: none;
`;

const vidDiv = (url, width, height) => {
  if (!url) {
    return <></>;
  }
  return (
    <Box
      sx={{
        width: width || "100vw",
        height: height || "50vw",
        maxWidth: "800px",
        maxHeight: "450px",
        margin: "auto",
      }}
    >
      <IF
        src={`https://api.vadoo.tv/iframe_test?id=${url}`}
        width="100%"
        height="100%"
      />
    </Box>
  );
};

const ResultDiv = styled.div`
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
`;

const ResultDivImg = styled.img`
  width: 100%;
  background: white;
  border-radius: 50%;
`;

const ResultDivBubbleContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
`;

const LogoImg = styled.img`
  position: absolute;
  left: 10%;
  top: 20%;
  width: 100px;
`;

const SLink = styled.a`
  color: red;
`;

const getFontSize = (name) => {
  if (name === "arla") {
    return "1.2em";
  }
  return "1em";
};
export default function Results({
  history,
  finalGrades,
  sections,
  overall,
  headers,
  overallGrade,
  conditionals,
  isLookback,
  completedSections,
}) {
  const [open, setOpen] = useState([]);
  const [loading, setLoading] = useState(false);

  const orgName = useSelector((state) => state.login.config.organizationName);
  const name = useSelector((state) => state.login.config.name);
  const userInfo = useSelector((state) => state.login.config);

  const theme = useTheme();

  const { View } = useLottie(
    {
      loop: false,
      autoplay: true,
      animationData: confetti,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    {
      height: "100%",
      widht: "100%",
    }
  );

  const toggle = (s) => {
    const newOpen = [...open];
    if (newOpen.includes(s)) {
      setOpen(newOpen.filter((sc) => sc !== s));
    } else {
      setOpen([...newOpen, s]);
    }
  };

  const heads = { ...headers };
  for (const s of Object.keys(history)) {
    if (!completedSections.includes(s)) {
      delete heads[s];
    }
  }

  const updateDB = async () => {
    const userRef = doc(db, "users", auth.currentUser.uid);
    await setDoc(doc(db, `users/${auth.currentUser.uid}/quizHistory`, v4()), {
      date: moment().format("YYYY/MM/DD"),
      history,
      finalGrades,
      sections,
      overall,
      headers,
      overallGrade,
      conditionals,
      completedSections,
    });
    await updateDoc(userRef, {
      currentQuiz: {
        position: ["budgeting", 0],
        conditionals: {},
        headers: {},
        finalGrades: {},
        skips: {},
        history: {},
      },
    });
  };
  const updateSql = async () => {
    const token = await auth.currentUser.getIdToken();

    await axios({
      method: "POST",
      url: `${config.apiUrl}/quiz`,
      headers: {
        Authorization: token,
      },
      data: JSON.stringify(
        makeResultUpdateBody(
          auth.currentUser.uid,
          history,
          finalGrades,
          sections,
          overallGrade,
          userInfo
        )
      ),
    });
  };

  const sendEmail = async () => {
    await axios({
      method: "POST",
      url: "/.netlify/functions/emailResults",
      // url: "https://upbeat-montalcini-34e93f.netlify.app/.netlify/functions/emailResults",
      data: JSON.stringify({
        body: makeHTML(
          { history, finalGrades, sections, overall, overallGrade },
          name,
          theme.palette.primary.main
        ),
        recipient: auth.currentUser.email,
      }),
    });
  };

  const postData = async () => {
    setLoading(true);
    updateDB();
    if (!process.env.REACT_APP_IS_TEST) {
      updateSql();
    }
    sendEmail();
  };

  useEffect(() => {
    if (!isLookback && auth.currentUser) {
      postData();
    }
  }, []);

  const [showConfetti, setShowConfetti] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowConfetti(false);
    }, 1000);
  }, []);

  return (
    <Grid container spacing={2} textAlign="center">
      {showConfetti && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100vw",
            zIndex: 999,
          }}
        >
          {View}
        </Box>
      )}
      <Grid item xs={12} md={8} m={"auto"} my={4} sx={{ position: "relative" }}>
        <img src={Banner} width="100%" />
        <Logo companyName={orgName} width="200px" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Typography
          variant="h4"
          sx={{
            color: "primary.main",
            display: "inline-block",
            background: "white",
            px: 5,
            py: 3,
            borderRadius: 10,
            boxShadow: "0px 0px 20px 20px rgba(0,0,0,0.05)",
            transform: "translateY(-50%)",
            fontWeight: "bold",
          }}
        >
          Congratulations on completing your assessment
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} m={"auto"} my={4}>
        <Typography variant="h3">Here are your results</Typography>
      </Grid>
      <Grid item xs={12} md={8} m={"auto"}>
        <Typography variant="h4" mb={4}>
          Your overall financial fitness score
        </Typography>
        <Bubble score={overallGrade} size={200} spin />
      </Grid>
      <Grid item xs={12} md={8} m={"auto"} my={4}>
        <Box
          sx={{
            borderRadius: 3,
            border: "1px solid",
            borderColor: "primary.main",
            mb: 10,
            maxHeight: "350px",
          }}
        >
          <Typography variant="h4" p={3}>
            Your individual financial fitness scores
          </Typography>
          <Box
            sx={{
              background: "white",
              borderRadius: 3,
              mx: 3,
              [theme.breakpoints.down("md")]: {
                mx: 0,
              },
            }}
          >
            <HeaderBubbles headers={heads} conditionals={conditionals} />
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={8}
        m={"auto"}
        sx={{
          [theme.breakpoints.down("md")]: {
            mt: 10,
          },
        }}
      >
        <Typography variant="h4" p={2}>
          Understanding your scores and what areas to prioritise
        </Typography>
        <Typography
          fontSize={getFontSize(orgName)}
          p={2}
          sx={{ whiteSpace: "pre-line", textAlign: "left" }}
        >
          Based on your answers, and using a traffic light system, we have
          scored you between an A and an F, with A being the highest score and F
          the lowest. The areas with the lowest scores are those that you need
          to prioritise.
          <br></br>
          <br></br>
          We have priorities your financial fitness score based on what you
          scored for each section, with the area with the lowest scores being
          first. We recommend that you look at the actionable tips and
          suggestions for further reading based on the areas with your lowest
          score.
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} m={"auto"}>
        <Typography variant="h4" p={2} m="auto">
          Your overall score
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} m="auto">
        {vidDiv(overall.videos[overallGrade].split("=")[1])}
      </Grid>
      <Grid item xs={12} md={8} m={"auto"}>
        <Typography
          fontSize={getFontSize(orgName)}
          sx={{ whiteSpace: "pre-line", textAlign: "left", p: 2 }}
        >
          {overall.scoring[overallGrade]}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} m={"auto"} my={6}>
        <Typography variant="h4">
          A breakdown of your individual results
        </Typography>
      </Grid>
      {Object.keys(history)
        .filter((s) =>
          completedSections
            ? completedSections.includes(s)
            : history[s].length > 2
        )
        .sort((a, b) => {
          return headers[a].grade > headers[b].grade ? -1 : 0;
        })
        .map((s) => {
          return (
            <>
              <br />
              <Grid
                item
                xs={12}
                md={8}
                key={s}
                p={2}
                sx={{
                  display: "block",
                  margin: "20px auto",
                  borderRadius: "20px",
                  border: "1px solid",
                  borderColor: "primary.main",
                }}
              >
                <Box
                  sx={{
                    maxHeight: 200,
                    p: 2,
                    backgroundColor: "primary.main",
                    borderRadius: 3,
                  }}
                >
                  <ResultDiv>
                    <Icon
                      width="100%"
                      section={s}
                      s="
                      background: white;
                      border-radius: 50%;
                      "
                    />
                    <ResultDivBubbleContainer>
                      <Bubble score={finalGrades[s]} size={55} pulse={true} />
                    </ResultDivBubbleContainer>
                  </ResultDiv>
                  <Typography
                    variant="h3"
                    sx={{
                      background: "white",
                      p: 1,
                      borderRadius: 3,
                      boxShadow: "0px 0px 20px 20px rgba(0,0,0,0.05)",
                      transform: "translateY(50%)",
                    }}
                  >
                    {["Renter", "Homeowner"].includes(sections[s].title.trim())
                      ? "Household Spending"
                      : sections[s].title}
                  </Typography>
                </Box>
                {open.includes(s) && (
                  <>
                    {sections[s].videos.a && (
                      <Box
                        sx={{
                          mt: 8,
                        }}
                      >
                        {vidDiv(
                          sections[s].videos[finalGrades[s]].split("=")[1],
                          "60vw",
                          "30vw"
                        )}
                      </Box>
                    )}
                    <Box
                      p={2}
                      mt={4}
                      sx={{
                        mt: 4,
                        [theme.breakpoints.down("md")]: {
                          mt: 10,
                        },
                      }}
                    >
                      <Typography
                        fontSize={getFontSize(orgName)}
                        whiteSpace="pre-line"
                        textAlign={"left"}
                      >
                        {sections[s].scoring[finalGrades[s]]}
                      </Typography>
                    </Box>
                  </>
                )}
                {history[s]
                  .filter((a) => a.comment && open.includes(s))
                  .map((a, idx) => {
                    return (
                      <div key={idx + s}>
                        <Box p={2}>
                          <Typography
                            pb={2}
                            textAlign="left"
                            variant="h4"
                            sx={{ color: "primary.main" }}
                          >
                            {a.heading}
                          </Typography>
                          <Typography
                            fontSize={getFontSize(orgName)}
                            sx={{ whiteSpace: "pre-line", textAlign: "left" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: a.comment,
                              }}
                            />
                          </Typography>
                          {a.link.title && (
                            <>
                              <Typography
                                fontSize={getFontSize(orgName)}
                                textAlign="left"
                                mt={2}
                                sx={{ fontWeight: "bold", color: "maroon" }}
                              >
                                {a.tip}
                                {" - "}
                                <SLink target="_blank" href={a.link.url}>
                                  {a.link.title}
                                </SLink>
                              </Typography>
                            </>
                          )}
                        </Box>
                      </div>
                    );
                  })}
                <Button
                  sx={{
                    mt: 8,
                    mb: 2,
                    borderRadius: 5,
                    fontWeight: "bold",
                    textTransform: "none",
                    [theme.breakpoints.down("md")]: {
                      mt: 15,
                    },
                  }}
                  variant="outlined"
                  onClick={() => toggle(s)}
                >
                  {open.includes(s)
                    ? "minimise"
                    : "Click here to read about your score in this section"}
                </Button>
              </Grid>
            </>
          );
        })}
      <Grid item xs={12}>
        <Typography textAlig="center" p={4}>
          This content is for guidance and educational purposes only and is
          generic in nature. People & Technology Limited, or its consumer
          website, HelpandAdvice.co.uk, do not offer regulated financial advice.
          We are a guidance service only. Please seek independent financial
          advice from a suitably qualified and regulated professional adviser
          for financial issues or other suitable professionals for other issues
          that may be raised through the wellbeing assessment tool or on the
          Help and Advice website.
          <br></br>
          <br></br>
          People & Technology Limited are registered in England & Wales (company
          number - 13319892). Data Protection Registration - ZB223920. Please
          click{" "}
          <a target="_blank" href="https://helpandadvice.co.uk/privacy-policy/">
            here
          </a>{" "}
          to view our privacy policy.
        </Typography>
      </Grid>
    </Grid>
  );
}
