// export const handlePositionChange =  (position) => {
//     //if end do modal
//     //
// }

// // answers component
// export const answerClick = () => {}

// export const getPreviousSection = (sections, position) => {
//     return sections[sections[position[0]].
// }

export const calculateSectionScore = (history, position) => {
  return history[position[0]].reduce((a, i) => {
    return +a + +i.score;
  }, 0);
};

export const getGrade = (
  sectionKey,
  score,
  history,
  sections,
  skips,
  completedSections,
  boundaries = [25, 41, 59, 74, 89, 100]
) => {
  //Conditional Logic
  const reductions = skips.reduce((a, skip) => {
    const newA = { ...a };
    if (skip.fromSection === skip.toSection) {
      const existing = a[skip.fromSection] || 0;
      const reduction = 3 * (skip.toNumber - skip.fromNumber);
      newA[skip.fromSection] = existing + reduction;
    }
    return newA;
  }, {});

  const maxSection = (sectionKey) => {
    const mx = sections[sectionKey].questions.reduce((ac, q) => {
      const ansScores = q.answers.map((a) => +a.score);
      return +ac + Math.max(...ansScores);
    }, 0);

    // max after conditionals considered
    return mx - (reductions[sectionKey] || 0);
  };

  let totalPotential = 0;

  if (sectionKey === "overall") {
    const sects = Object.keys(history);
    totalPotential = sects.reduce((a, e) => {
      if (completedSections.includes(e)) {
        return +a + maxSection(e);
      }
      return +a;
    }, 0);
  } else {
    totalPotential = maxSection(sectionKey);
  }

  const grades = ["f", "e", "d", "c", "b", "a"];

  for (let i = 0; i < boundaries.length; i++) {
    if (score <= Math.round(totalPotential * (boundaries[i] / 100))) {
      return grades[i];
    }
  }
  return "a";
};
