import React, { useState, useEffect } from "react";

import Lottie from "lottie-react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import SecureLogin from "../../assets/lotties/secureLogin.json";
import { Navigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { actions } from "../logInScreen/slice";

import financialEducationLogo from "../../assets/images/financialeducationlogo.png";
import { useLocation } from "react-router";
import Logo from "../../components/Logo";
import { themeconfig } from "../../ThemeConfigs";
import Login from "./login";
import Register from "./register";

export default function BespokeLoginScreen() {
  const location = useLocation();
  const oName = location.pathname.split("/")[1];
  const backgroundColor = themeconfig[oName].palette.primary.main;

  const dispatch = useDispatch();

  const [mode, setMode] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    console.log("back");
    setMode(null);
  };

  useEffect(() => {
    dispatch(actions.resetState());

    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (mode) {
      window.history.pushState(null, null, window.location.pathname);
    }
  }, [mode]);

  const isDeactivated = oName === "everton";

  if (redirect) {
    // dont use mfa for demo
    // if (oName === "demo") {
    //   return <Navigate to={`/landing/demo`} />;
    // }
    return <Navigate to={redirect} />;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <img src={financialEducationLogo} width="100px" />
      </Grid>
      <Grid
        item
        xs={12}
        md={10}
        sx={{
          borderRadius: 3,
          my: 5,
          p: 2,
        }}
        backgroundColor={backgroundColor}
      >
        <>
          <Box fullWidth sx={{ maxWidth: 300, m: "auto", my: 4, px: 10 }}>
            <Logo
              companyName={oName}
              width="100%"
              useSecondary={oName === "adidas"}
            />
          </Box>

          {isDeactivated && (
            <Box
              sx={{
                p: 2,
              }}
            >
              <Typography
                sx={{
                  p: 4,
                  fontSize: "20px",
                  background: "white",
                  borderRadius: 2,
                  maxWidth: "500px",
                  m: "auto",
                }}
              >
                The financial wellbeing assessment tool is currently unavailable
                as it is being updated for 2024. Staff will be notified when it
                is made live again
              </Typography>
            </Box>
          )}

          {!mode && !isDeactivated && (
            <>
              <Typography p={5} color="white">
                Do you want to log in or create a new account?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  height: 200,
                  pb: 5,
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => setMode("login")}
                  sx={{
                    "&:hover": {
                      backgroundColor: "warning.main",
                      color: "white",
                    },
                    fontWeight: "bold",
                    backgroundColor: "white",
                    color: backgroundColor,
                    flex: 1,
                    height: "100%",
                    m: 2,
                  }}
                >
                  Log in
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setMode("register")}
                  sx={[
                    {
                      "&:hover": {
                        backgroundColor: "warning.main",
                        color: "white",
                      },
                    },
                    {
                      fontWeight: "bold",
                      backgroundColor: "white",
                      color: backgroundColor,
                      flex: 1,
                      height: "100%",
                      m: 2,
                    },
                  ]}
                >
                  Create new account
                </Button>
              </Box>
            </>
          )}
          {!isDeactivated && (
            <>
              <Login
                mode={mode}
                backgroundColor={backgroundColor}
                setRedirect={setRedirect}
                organizationName={oName}
              />
              <Register
                mode={mode}
                organizationName={oName}
                setRedirect={setRedirect}
              />
            </>
          )}
        </>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          People & Technology Limited are registered in England & Wales (company
          number - 13319892). Data Protection Registration - ZB223920. Please
          click{" "}
          <a target="_blank" href="https://helpandadvice.co.uk/privacy-policy/">
            here
          </a>{" "}
          to view our privacy policy.
        </Typography>
      </Grid>
    </Grid>
  );
}
