import * as utils from "./utils";

export const onAnswerClick = (
  position,
  a,
  setHistory,
  history,
  setPosition,
  skips,
  setSkips,
  toggleModal,
  updateModal,
  setModalInfo,
  sections,
  conditionals,
  setConditionals,
  setOverallGrade,
  setFinalGrades,
  toggleResults,
  headers,
  setHeaders,
  completedSections,
  setCompletedSections,
  boundaries
) => {
  // incomplete section
  const incompleteSections = Object.keys(headers).filter(
    (key) => !completedSections.includes(key)
  );

  //conditional logic
  if (a.conditional) {
    setConditionals({
      ...conditionals,
      [a.conditional[0]]: a.conditional[1],
    });
  }

  const newHistory = {
    ...history,
    [position[0]]: [
      ...history[position[0]],
      {
        heading: sections[position[0]].questions[position[1]].heading,
        ...a,
        id: position[1],
      },
    ],
  };

  setHistory(newHistory);

  const nextPosition = [position[0], +position[1] + 1];

  if (a.skipTo) {
    const split = a.skipTo.split(",");
    if (split.length === 2) {
      nextPosition[0] = split[0];
      nextPosition[1] = +split[1];
    } else {
      nextPosition[0] = position[0];
      nextPosition[1] = +a.skipTo - 1;
    }
    setSkips([
      ...skips,
      {
        fromSection: position[0],
        fromNumber: position[1],
        toSection: nextPosition[0],
        toNumber: nextPosition[1],
      },
    ]);
  }

  //if at last question of section
  if (position[1] === sections[position[0]].questions.length - 1) {
    setCompletedSections([...completedSections, position[0]]);
    const o = sections[position[0]].order;
    let nextRef = +o + 1;
    if (position[0] === "homeowner") {
      nextRef++;
    }

    // get the order property of the next incomplete section
    let nextOrder = incompleteSections
      .map((key) => sections[key].order)
      .sort()
      .find((orderNumber) => orderNumber >= nextRef);

    // if there is nothing incomplete AFTER this section, what about BEFORE?
    if (!nextOrder) {
      // check all sections have been completed
      // if not, jump to first incomplete section
      // user must complete atleast 1 housing section
      if (
        incompleteSections.length > 1 &&
        incompleteSections.includes("homeowner") &&
        incompleteSections.includes("renter")
      ) {
        // identify first incomplete section
        nextOrder = incompleteSections
          .map((key) => sections[key].order)
          .sort()[0];
      }
    }

    const nextExists = Object.keys(sections).find(
      (s) => sections[s].order === nextOrder
    );

    if (nextExists) {
      setSkips([
        ...skips,
        {
          fromSection: position[0],
          fromNumber: position[1],
          toSection: nextExists,
          toNumber: 0,
        },
      ]);
      updateModal(
        setModalInfo,
        newHistory,
        position,
        sections,
        toggleModal,
        nextExists,
        skips,
        completedSections,
        boundaries
      );
      setPosition([nextExists, 0]);
      return;
    } else {
      // if not captured by above, therefore final module
      const newHeaders = { ...headers };
      newHeaders[position[0]] = {
        ...newHeaders[position[0]],
        grade: utils.getGrade(
          position[0],
          utils.calculateSectionScore(history, position),
          history,
          sections,
          skips,
          completedSections,
          boundaries
        ),
      };
      setHeaders(newHeaders);
      endQuiz(
        history,
        sections,
        setOverallGrade,
        setFinalGrades,
        toggleResults,
        skips,
        completedSections,
        boundaries
      );
      return;
    }
  }

  setPosition(nextPosition);
};

export const onBackButtonClick = (
  history,
  setPosition,
  setHistory,
  position,
  skips,
  setSkips,
  headers,
  setHeaders
) => {
  const newHist = [...history[position[0]]];
  newHist.pop();
  setHistory({
    ...history,
    [position[0]]: newHist,
  });

  if (skips.length) {
    const { toNumber, toSection, fromNumber, fromSection } =
      skips[skips.length - 1];
    if (toSection === position[0] && toNumber === position[1]) {
      setPosition([fromSection, fromNumber]);
      setHeaders({
        ...headers,
        [fromSection]: {
          ...headers[fromSection],
          grade: "",
        },
      });
      const newSkips = [...skips];
      newSkips.pop();
      setSkips(newSkips);
      return;
    }
  }

  setPosition([position[0], position[1] - 1]);
};

export const updateModal = (
  setModalInfo,
  history,
  position,
  sections,
  toggleModal,
  nextSection,
  skips,
  completedSections,
  boundaries
) => {
  let title = sections[position[0]].title;

  if (["renter", "homeowner"].includes(position[0])) {
    title = "Household Spending";
  }

  setModalInfo({
    key: position[0],
    title,
    next: ["renter", "homeowner"].includes(nextSection)
      ? "Household Spending"
      : sections[nextSection].title,
    nextInfo: sections[nextSection].subheading,
    nextSection,
    grade: utils.getGrade(
      position[0],
      utils.calculateSectionScore(history, position),
      history,
      sections,
      skips,
      completedSections,
      boundaries
    ),
    subheading: sections[position[0]].subheading,
  });
  toggleModal(true);
};

export const endQuiz = (
  history,
  sections,
  setOverallGrade,
  setFinalGrades,
  toggleResults,
  skips,
  completedSections,
  boundaries
) => {
  //get grades for bubbles
  let totalScore = 0;
  const finalGrades = {};

  console.log(completedSections);

  for (const s of Object.keys(history)) {
    if (completedSections.includes(s)) {
      const score = utils.calculateSectionScore(history, [s]);
      totalScore += score;
      finalGrades[s] = utils.getGrade(
        s,
        score,
        history,
        sections,
        skips,
        completedSections,
        boundaries
      );
    }
  }

  setFinalGrades(finalGrades);

  //overall
  const overallGrade = utils.getGrade(
    "overall",
    totalScore,
    history,
    sections,
    skips,
    completedSections,
    boundaries
  );
  setOverallGrade(overallGrade);
  toggleResults(true);

  // show end screen
};
