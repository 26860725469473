import React, { useState } from "react";
import {
  sendPasswordResetEmail,
  updateEmail,
  verifyBeforeUpdateEmail,
} from "@firebase/auth";

import {
  TextField,
  Box,
  Grid,
  Button,
  CircularProgress,
  Typography,
  Alert,
} from "@mui/material";

import { auth } from "../../firebase";
import styled from "styled-components";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const StyledTextField = styled(TextField)`
  &&,
  * {
    color: ${(p) => p.theme.palette.primary.light} !important;
  }
  label {
    background: white;
    padding: 2px;
  }
  input {
    border: 4px solid ${(p) => p.theme.palette.primary.light} !important;
    border-radius: 5px;
  }
`;

export default function Account() {
  const theme = useTheme();

  const { isMfaUser } = useSelector((state) => state.login.config);

  const [email, setEmail] = useState({ email: "", confirm: "" });
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const onEmailChange = (value, key) => {
    setEmail({
      ...email,
      [key]: value,
    });
  };
  const postEmailUpdate = async () => {
    try {
      setEmailLoading(true);
      setEmailError(false);
      setEmailSuccess(false);
      if (isMfaUser) {
        await verifyBeforeUpdateEmail(auth.currentUser, email.email);
      } else {
        await updateEmail(auth.currentUser, email.email);
      }
      setEmailLoading(false);
      setEmailSuccess(true);
    } catch (e) {
      console.log(e);
      setEmailLoading(false);
      setEmailError(e.code);
    }
  };

  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  const postPasswordUpdate = async () => {
    try {
      setPasswordLoading(true);
      setPasswordError(false);
      setPasswordSuccess(false);
      await sendPasswordResetEmail(auth, auth.currentUser.email);
      setPasswordSuccess(true);
      setPasswordLoading(false);
    } catch (e) {
      setPasswordLoading(false);
      setPasswordError(true);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} p={2}>
        <Typography variant="h4" fontWeight="bold">
          Account settings
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} margin="auto" p={2}>
        <Typography variant="h4" fontWeight="bold">
          Your email details
        </Typography>

        <Box sx={{ p: 2, width: "100%" }}>
          <StyledTextField
            theme={theme}
            fullWidth
            value={auth.currentUser.email}
            label={
              <Typography sx={{ fontSize: "20px" }}>
                This is your email address to login, receive your assessments
                and follow-up emails
              </Typography>
            }
            disabled
            helperText="*If you do not receive an email copy of your report, please check your junk or spam folders"
          />
        </Box>
        <Typography variant="h4" fontWeight="bold" pt={4}>
          Change Your Email Address
        </Typography>
        <Box sx={{ p: 2, width: "100%" }}>
          <TextField
            fullWidth
            onChange={(e) => onEmailChange(e.target.value, "email")}
            value={email.email}
            label="New email"
          />
        </Box>
        <Box sx={{ p: 2, width: "100%" }}>
          <TextField
            fullWidth
            onChange={(e) => onEmailChange(e.target.value, "confirm")}
            value={email.confirm}
            label="Confirm  new email"
          />
        </Box>
        <Box sx={{ p: 2, width: "100%" }}>
          {emailSuccess && (
            <Alert severity="success">
              {isMfaUser
                ? `Instructions have been sent to your new email address`
                : "Email updated successfully"}
            </Alert>
          )}
          {emailError && (
            <Alert severity="error">
              {emailError === "auth/requires-recent-login"
                ? "Error. Please re-authenticate and try again"
                : "Error updating email. Please try again later"}
            </Alert>
          )}
          {!emailLoading && (
            <Button
              onClick={postEmailUpdate}
              variant="contained"
              disabled={
                !(validateEmail(email.email) && email.email === email.confirm)
              }
            >
              Update email
            </Button>
          )}
          {emailLoading && <CircularProgress size="100" />}
        </Box>
      </Grid>
      <Grid item xs={12} md={6} p={2}>
        <Typography variant="h4" fontWeight="bold">
          Password
        </Typography>
        <Box sx={{ p: 2, width: "100%" }}>
          <Typography>
            Use this button to receive a password recovery link.
          </Typography>
        </Box>
        <Box sx={{ p: 2, width: "100%" }}>
          {passwordSuccess && (
            <Alert severity="success">
              A password recovery link has been sent to your email address.
            </Alert>
          )}
          {passwordError && (
            <Alert severity="error">Error sending password update email</Alert>
          )}
          {!passwordLoading && (
            <Button onClick={postPasswordUpdate} variant="contained">
              Request recovery link
            </Button>
          )}
          {passwordLoading && <CircularProgress size="100" />}
        </Box>
      </Grid>
    </Grid>
  );
}
