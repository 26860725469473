import React, { useEffect, useState } from "react";
import { useLottie } from "lottie-react";
import { useTheme } from "@mui/styles";
import styled from "styled-components";
import { Grid, Box, Button, Typography } from "@mui/material";
import SideBubbles from "./SideBubbles";
import Bubble from "../../../components/Bubble";
import Icon from "../../../components/Icon";
import pointyFingerLight from "../../../assets/lotties/pointyFingerLight.json";

const GlimmerButton = styled.div`
  && {
    font-weight: bold;
    font-size: 18px;

    height: 66%;
    max-width: 300px;
    width: 66%;
    margin: auto;
    margin-top: 50px;
    cursor: pointer;

    display: block;
    position: relative;
    color: white;
    // border: 1px solid ${(p) => p.theme.palette.primary.main};
    background-color: ${(p) => p.theme.palette.primary.main};
    border-radius: 30px;

    box-shadow: 0 0 0 0 transparent;
    transition: 0.2s ease-in;
    overflow: hidden;

    text-align: center !important;
    padding: 20px;
    cursor: pointer;

    &:active {
      box-shadow: 0 0 0 0 transparent;
      transition: 0.2s ease-out;
    }

    &:before {
      animation: shine 3s 0s infinite;
      content: "";
      display: block;
      width: 0px;
      height: 100%;
      position: absolute;
      top: 0%;
      left: 0%;

      opacity: 0;
      background: white;
      box-shadow: 0 0 30px 3px white;
      transform: skewX(-30deg);
    }

    p {
      margin: auto;
      padding: 10px;
      font-size: 30px;
      text-align: center;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    background: ${(p) => p.theme.palette.primary.main};
    color: white;
    :hover {
      background: ${(p) => p.theme.palette.primary.light};

      color: white;
    }
  }
`;

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 100;
  overflow-y: scroll;
  position: fixed;
`;

export default function Modal({
  modalInfo,
  show,
  toggleModal,
  headers,
  setHeaders,
  endQuiz,
}) {
  const theme = useTheme();
  const [page, setPage] = useState(0);

  const { View } = useLottie({
    loop: true,
    autoplay: true,
    animationData: pointyFingerLight,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  const updateHeaders = () => {
    const newHeaders = {
      ...headers,
      [modalInfo.key]: { ...headers[modalInfo.key], grade: modalInfo.grade },
    };
    setHeaders(newHeaders);
  };

  const cont = () => {
    window.scrollTo(0, 0);
    toggleModal(false);
    updateHeaders();
  };

  const eq = () => {
    updateHeaders();
    endQuiz();
  };

  const next = () => {
    setPage(1);
  };

  if (!show && page === 1) {
    setPage(0);
  }

  // useEffect(() => {
  //   if (show && !localStorage.getItem("fullQuiz")) {
  //     eq();
  //   }
  // }, [show]);

  if (show) {
    return (
      <Container>
        <Grid container justifyContent={"center"}>
          {page === 0 && (
            <>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 2,
                  }}
                >
                  <Icon
                    section={modalInfo.key}
                    width="75px"
                    margin="auto 10px"
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      p: 2,
                      my: "auto",
                    }}
                  >
                    {modalInfo.title}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      p: 2,
                      fontWeight: "bold",
                    }}
                  >
                    For this area of your life you scored
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Bubble
                  // spin={true}
                  mx="auto"
                  score={modalInfo.grade}
                  size={150}
                />
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <GlimmerButton
                    theme={theme}
                    variant="contained"
                    color="primary"
                    onClick={next}
                  >
                    Click to continue
                  </GlimmerButton>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <SideBubbles
                  title={"What Your Score Means"}
                  lite
                  headers={{
                    A: {
                      grade: "a",
                      title:
                        "You have very good control in this part of your life",
                      order: 1,
                    },
                    B: {
                      grade: "b",
                      title: "You maintain stable control in this area",
                      order: 2,
                    },
                    C: {
                      grade: "c",
                      title: "You have adequate control in this area",
                      order: 3,
                    },
                    D: {
                      grade: "d",
                      title:
                        "This area is a little precarious and you should look to take action",
                      order: 4,
                    },
                    E: {
                      grade: "e",
                      title:
                        "You are facing significant challenges and action should be prioritised",
                      order: 5,
                    },
                    F: {
                      grade: "f",
                      title:
                        "Very urgent action needed and immediate attention given",
                      order: 6,
                    },
                  }}
                  conditionals={{}}
                />
              </Grid>
            </>

            // <Box
            //   sx={{
            //     display: "flex",
            //     flexDirection: "row-reverse",
            //     justifyContent: "center",
            //     [theme.breakpoints.down("md")]: {
            //       flexDirection: "column",
            //     },
            //   }}
            // >
            //   <Grid item xs={12} mx='auto'>
            //     <Grid item xs={12} mx='auto'>
            //       <Icon section={modalInfo.key} width="100" />
            //       <Typography
            //         variant="h2"
            //         sx={{
            //           [theme.breakpoints.down("md")]: {
            //             fontSize: "2em",
            //           },
            //         }}
            //       >
            //         {modalInfo.title}
            //       </Typography>
            //     </Grid>
            //     <Grid item xs={12} mx='auto' mt={3}>
            //       <Typography fontSize="1.1em">
            //         For this area you scored
            //       </Typography>
            //     </Grid>
            //     <Grid item xs={12} mx='auto' mt={6}>
            //       <Bubble
            //         // spin={true}
            //         mx='auto'
            //         score={modalInfo.grade}
            //         size={150}
            //         pulse
            //       />

            //       <Box
            //         sx={{
            //           width: "100%",
            //           pt: 4,
            //           display: "flex",
            //           flexDirection: "column-reverse",
            //           justifyContent: "center",
            //         }}
            //       >
            //         {/* <Box
            //           sx={{
            //             width: "200px",
            //             margin: "auto",
            //             transform: "rotate(-90deg)",
            //           }}
            //         >
            //           <Lottie
            //             options={{
            //               loop: true,
            //               autoplay: true,
            //               animationData: pointyFinger,
            //               rendererSettings: {
            //                 preserveAspectRatio: "xMidYMid slice",
            //               },
            //             }}
            //             isStopped={false}
            //             isPaused={false}
            //           />
            //         </Box> */}
            //         <GlimmerButton
            //           theme={theme}
            //           variant="contained"
            //           color="primary"
            //           onClick={next}
            //         >
            //           Click to continue
            //         </GlimmerButton>
            //       </Box>
            //     </Grid>
            //   </Grid>
            //   <Grid item xs={12} md={4} display="flex" mx='auto' mt={5}>
            //     <Grid
            //       container
            //       sx={{
            //         py: 2,
            //         backgroundColor: "whitesmoke",
            //         borderRadius: 4,
            //         minWidth: "400px",
            //         maxWidth: "650px",
            //       }}
            //     >
            //       <Grid item xs={12} mx='auto'>
            //         <Typography
            //           variant="h6"
            //           fontWeight="bold"
            //           color="primary.main"
            //           p={1}
            //           borderRadius={3}
            //           m={2}
            //         >
            //           Understanding your score
            //         </Typography>
            //         <Typography p={2} fontSize="0.9em">
            //           A is the highest score and F is the lowest. The areas with
            //           the lowest scores are those that you need to prioritise.
            //         </Typography>
            //       </Grid>
            //       <Grid
            //         item
            //         xs={12}
            //         mx='auto'
            //         display="flex"
            //         justifyContent="center"
            //         m="auto"
            //       >
            //         <Box m={"0 auto"} position="relative">
            //           <Typography
            //             sx={{
            //               m: "auto",
            //               fontWeight: "bold",
            //             }}
            //           >
            //             This area of your <br></br>
            //             life is being managed
            //           </Typography>
            //           <SideBubbles
            //             lite
            //             headers={{
            //               A: {
            //                 grade: "a",
            //                 title: "",
            //                 order: 1,
            //               },
            //               B: {
            //                 grade: "b",
            //                 title: "",
            //                 order: 2,
            //               },
            //               C: {
            //                 grade: "c",
            //                 title: "",
            //                 order: 3,
            //               },
            //               D: {
            //                 grade: "d",
            //                 title: "",
            //                 order: 4,
            //               },
            //               E: {
            //                 grade: "e",
            //                 title: "",
            //                 order: 5,
            //               },
            //               F: {
            //                 grade: "f",
            //                 title: "",
            //                 order: 6,
            //               },
            //             }}
            //             conditionals={{}}
            //           />
            //           <Typography
            //             sx={{
            //               m: "auto",
            //               fontWeight: "bold",
            //             }}
            //           >
            //             You need to urgently <br></br>prioritise this area
            //           </Typography>
            //         </Box>
            //       </Grid>
            //     </Grid>
            //   </Grid>
            // </Box>
          )}
          {page === 1 && (
            <>
              <Grid
                container
                rowSpacing={1}
                p={3}
                minHeight="100vh"
                sx={{
                  background:
                    "linear-gradient(45deg, rgba(225,255,245,1) 0%, rgba(255,255,255,1) 50%, rgba(225,255,245,1) 100%);",

                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Grid
                  item
                  xs={10}
                  mx="auto"
                  pt="3"
                  sx={{
                    maxWidth: "900px !important",
                  }}
                >
                  <Icon
                    width="100px"
                    s="
                    border-radius: 50%;
                    background: white;
                    "
                    section={modalInfo.nextSection}
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      p: 2,
                      fontWeight: "bold",
                    }}
                  >
                    The next section is on {modalInfo.next}
                  </Typography>
                  <Box
                    sx={{
                      p: 2,
                      boxShadow: "0px 0px 20px 20px rgba(0,0,0,0.05)",
                      borderRadius: 4,
                      background: "white",
                      width: "100%",
                    }}
                  >
                    <Typography
                      p={2}
                      sx={{
                        fontSize: "17px",
                      }}
                    >
                      {modalInfo.nextInfo}
                    </Typography>
                    <StyledButton
                      sx={{
                        borderRadius: 20,
                        fontWeight: "bold",
                        textTransform: "none",
                        p: 2,
                        px: 4,
                      }}
                      theme={theme}
                      m={3}
                      onClick={cont}
                    >
                      Click here to go to {modalInfo.next}
                    </StyledButton>
                  </Box>

                  <Typography
                    sx={{
                      mx: "auto",
                      width: "100%",
                      my: 0,
                      padding: 2,
                    }}
                  >
                    Or
                  </Typography>

                  <Box
                    sx={{
                      p: 2,
                      boxShadow: "0px 0px 20px 20px rgba(0,0,0,0.05)",
                      borderRadius: 4,
                      background: "white",
                      width: "100%",
                    }}
                  >
                    <Typography
                      p={2}
                      sx={{
                        fontSize: "17px",
                      }}
                    >
                      Finish the assessment here and get your overall score
                      based on the sections that you have completed. However, we{" "}
                      <b>strongly recommend</b> that you continue with the
                      assessment, and go to the next section (by pressing the
                      button above), as it will not take long to complete, and
                      you will get a more targeted overall financial wellbeing
                      score at the end.
                    </Typography>

                    <Button
                      sx={{
                        borderRadius: 20,
                        fontWeight: "bold",
                        textTransform: "none",
                        p: 2,
                        px: 4,
                      }}
                      m={2}
                      variant="outlined"
                      color="primary"
                      onClick={eq}
                    >
                      Click here to finish now
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    );
  } else {
    return <></>;
  }
}
