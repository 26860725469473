import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLottie } from "lottie-react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { query, collection, where, getDocs } from "firebase/firestore";
import { Navigate, Link } from "react-router-dom";

import { cmsDb } from "../../firebase";

import { Grid, Typography, Button, Box, Divider } from "@mui/material";

import Logo from "../../components/Logo";

import liverpoolWelcome from "../../assets/lotties/liverpool-landing.json";
import Welcome from "../../assets/lotties/welcome.json";
import CoffeeDrink from "../../assets/lotties/coffeeDrink.json";
import resourceFinancial from "../../assets/lotties/resourcesFinancial.json";
import resourceDaily from "../../assets/lotties/resourcesDaily.json";
import resourceHealth from "../../assets/lotties/resourcesHealth.json";
import resourceLegal from "../../assets/lotties/resourcesLegal.json";
import pointyFinger from "../../assets/lotties/pointyfinger.json";
import SplashScreen from "../../components/SplashScreen";

const GlimmerButton = styled.div`
  && {
    font-weight: bold;
    font-size: 18px;

    min-height: 66%;
    width: 66%;
    margin: 10px auto;

    display: flex;
    position: relative;
    color: white;
    // border: 1px solid ${(p) => p.theme.palette.primary.main};
    background-color: #f8302f;
    border-radius: 30px;

    box-shadow: 0 0 0 0 transparent;
    transition: 0.2s ease-in;
    overflow: hidden;

    &:active {
      box-shadow: 0 0 0 0 transparent;
      transition: 0.2s ease-out;
    }

    &:before {
      animation: shine 3s 0s infinite;
      content: "";
      display: block;
      width: 0px;
      height: 100%;
      position: absolute;
      top: 0%;
      left: 0%;

      opacity: 0;
      background: white;
      box-shadow: 0 0 30px 3px white;
      transform: skewX(-30deg);
    }

    p {
      margin: auto;
      padding: 10px;
      font-size: 30px;
      text-align: center;
    }
  }
`;

const ButtonWArrow = ({ theme }) => {
  const { View: Pointy } = useLottie({
    loop: true,
    autoplay: true,
    animationData: pointyFinger,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });
  return (
    <>
      <Grid
        item
        md={3}
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "none",
            maxWidth: 0,
            overflow: "visible",
          },
        }}
      >
        {Pointy}
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Link to="/home" style={{ textDecoration: "none" }}>
          <GlimmerButton theme={theme}>
            <p>Click here to start your financial wellbeing assessment</p>
          </GlimmerButton>
        </Link>
      </Grid>
    </>
  );
};

const hideChatBot = (orgName) => {
  return ["everton", "adidas", "liverpool"].includes(orgName);
};

export default function CompanyLandingPage() {
  let { displayName } = useSelector((state) => state.login.config);
  displayName = displayName === "Adidas" ? "adidas" : displayName;

  const org = useSelector((state) => state.login.config.organizationName);
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const { View: WelcomeLottie } = useLottie(
    {
      loop: true,
      autoplay: true,
      animationData: org === "liverpool" ? liverpoolWelcome : Welcome,
      rendererSettings: {
        // preserveAspectRatio: "xMidYMid slice",
      },
    },
    {
      width: "100%",
      height: "55%",
    }
  );

  const { View: CoffeeLottie } = useLottie(
    {
      loop: true,
      autoplay: true,
      animationData: CoffeeDrink,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    {
      width: "100%",
      height: "100%",
    }
  );

  const makeLottieProps = (animationData) => [
    {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    {
      width: "100%",
      height: "100%",
    },
  ];

  // respurce lotties
  const { View: healthlottie } = useLottie(makeLottieProps(resourceHealth));
  const { View: financialLottie } = useLottie(
    makeLottieProps(resourceFinancial)
  );
  const { View: dailyLottie } = useLottie(makeLottieProps(resourceDaily));
  const { View: legalLottie } = useLottie(makeLottieProps(resourceLegal));

  const resourceLotties = {
    Financial: financialLottie,
    "Health and Wellbeing": healthlottie,
    Legal: legalLottie,
    "Daily Life": dailyLottie,
  };

  const [visible, setVisible] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const updateVisible = (isvis, idx) => {
    const nv = [...visible];
    nv[idx] = isvis;
    setVisible(nv);
  };

  // const update = async () => {
  //   const adidas = doc(cmsDb, "questionsets", "WesAVj1WAJg1xaWU8nKr");
  //   await updateDoc(adidas, {
  //     landinPageContent: {
  //       paragraphs: [],
  //       video: "",
  //       resources: {
  //         resourceSections: [],
  //         resources: {},
  //       },
  //     },
  //   });
  // };

  console.log(content);

  const getContent = async (o) => {
    const q = query(collection(cmsDb, "questionsets"), where("name", "==", o));
    const found = await getDocs(q);
    if (found.empty) {
      setRedirect(true);
    }
    found.forEach((doc) => {
      const data = doc.data();
      setContent(data.landinPageContent);
    });
  };
  useEffect(() => {
    if (org && !content) {
      getContent(org);
    }
  }, [org]);

  useEffect(() => {
    if (!hideChatBot(org)) {
      const scriptTag1 = document.createElement("script");
      scriptTag1.setAttribute("id", "s1");
      const scriptTag2 = document.createElement("script");
      scriptTag2.setAttribute("id", "s2");

      scriptTag2.src =
        "https://cdn-app.continual.ly/js/embed/continually-embed.latest.min.js";

      const code = 'var continuallySettings = { appID: "jenrjzzp5rm7" }';
      scriptTag1.appendChild(document.createTextNode(code));

      document.body.appendChild(scriptTag1);
      document.body.appendChild(scriptTag2);
      return () => {
        try {
          document.body.removeChild(document.querySelector("#s1"));
          document.body.removeChild(document.querySelector("#s2"));
          document.querySelector("#continually-container").remove();
        } catch (e) {
          console.log(e);
        }
      };
    }
  }, []);

  if (redirect) {
    return <Navigate to={`/home`} />;
  }

  if (!content) {
    return <SplashScreen />;
  }

  console.log(hideChatBot(org));

  return (
    <Grid
      container
      textAlign="left"
      p={2}
      sx={
        !hideChatBot(org)
          ? {
              [theme.breakpoints.up("md")]: {
                paddingLeft: "50px",
                maxWidth: "calc( 100vw - 510px )",
              },
            }
          : { maxWidth: "1000px", margin: "auto" }
      }
    >
      <Logo companyName={org} width="200px" />
      <Grid item xs={12} m="auto" textAlign="center" p={3}>
        <Typography variant="h4" fontWeight="bold" color="primary.main">
          Welcome to the {displayName}
        </Typography>
        <Typography
          variant="h2"
          fontWeight="bold"
          color="secondary.main"
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "2em",
            },
          }}
        >
          Financial Wellbeing Assessment
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{content.paragraphs[0]}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ float: "right", width: "50%" }}>{WelcomeLottie}</Box>
        <Typography variant="h4" py={2} fontWeight="bold" color="primary.main">
          What is the tool?
        </Typography>
        <Typography>
          The tool asks you questions related to 7 areas of your life:
          <ul>
            <li>Budgeting</li>
            <li>Debt Management</li>
            <li>Pay & Allowances</li>
            <li>Household Spending</li>
            <li>Health Protection</li>
            <li>Pension & Savings</li>
            <li>Later Life Planning</li>
          </ul>
        </Typography>
        <Typography variant="h4" pb={2} fontWeight="bold" color="primary.main">
          What will the tool tell me?
        </Typography>
        <Typography>
          After you have answered the questions, you will receive a score for
          each of the 7 areas assessed.
          <br />
          <br />
          The tool will give you, using a traffic light system, a score between
          ‘A’ and ‘F’, with ‘A’ being the highest score and ‘F’ the lowest.
          <br />
          <br />
          At the end, you will receive a personalised report that explains the
          reasons for your score plus tips and guidance on what you can do to
          improve your score.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" py={2} fontWeight="bold" color="primary.main">
          How do I use it?
        </Typography>
        <Typography>
          The tool takes about 15 minutes to complete, so please make sure you
          have given yourself this time to work through the questions.
          <br />
          <br />
          You can access the tool further down this page.
        </Typography>
      </Grid>
      {org !== "liverpool" && (
        <Grid item xs={12} p={2}>
          <Typography variant="h4" fontWeight="bold" textAlign="center" p={2}>
            Please watch this short video before you start
          </Typography>
          <Box
            sx={{
              width: "100%",
              maxWidth: "700px",
              // maxHeight: "400px",
              aspectRatio: '16/9',
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              overflow: 'hidden'
            }}
          >
            <div
              style={{ width: "100%", height: "100%", position: 'relative', top: '-50px' }}
              dangerouslySetInnerHTML={{ __html: content.video }}
            ></div>

            {/* <iframe
            // class="elementor-video-iframe"
            style={{ border: "none" }}
            width="100%"
            height="100%"
            allowfullscreen=""
            title="vimeo Video Player"
            src={content.video}
          /> */}
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h4" py={2} fontWeight="bold" color="primary.main">
          What happens after I have completed my assessment?
        </Typography>
        <Typography>
          Over the course of the next few weeks and months, you will receive a
          series of emails providing you with lots of helpful and actionable
          advice on steps you can take to improve your financial wellbeing.
          <br />
          <br />
          Finally, for your own peace of mind, {displayName} will not see your
          underlying scores or personalised report. They will only see
          aggregated data, which in turn will be used to help identify what
          financial education and support programmes and products could be made
          available to support staff.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" py={2} fontWeight="bold" color="primary.main">
          What other help is there?
        </Typography>
        <Typography>
          Further down this page you can also access:
          <ol>
            <li>
              Organisations and websites that can help address the issues raised
              by the Tool
            </li>
            <li>
              A library of useful information if you want to learn more about
              any of the topics that are raised in the Tool.
            </li>
          </ol>
        </Typography>
      </Grid>
      <Grid item xs={6} md={4} p={2} mx="auto">
        {CoffeeLottie}
      </Grid>
      <Grid item xs={12} p={2}>
        <Typography
          variant="h2"
          fontWeight="bold"
          color="primary.main"
          textAlign="center"
          sx={{
            pb: 2,
            [theme.breakpoints.down("md")]: {
              fontSize: "2em",
            },
          }}
        >
          Grab a coffee and start your assessment
        </Typography>
        <Typography>
          The assessment will take you about 15 minutes to complete. So, please
          grab yourself a drink, turn off any distractions and focus on making
          your way through the questions.
          <br />
          <br />
          Please do try and answer as honestly as possible as this will ensure
          you get a more accurate score at the end.
          <br />
          <br />
          You can start the financial wellbeing assessment by clicking the red
          button below.
        </Typography>
      </Grid>
      <ButtonWArrow theme={theme} />
      <Grid item xs={12} p={2} sx={{ textAlign: "center" }}>
        <Divider sx={{ backgroundColor: "black", my: 10 }} />
        <img
          alt=""
          style={{ width: "66%", margin: "auto" }}
          src={`https://firebasestorage.googleapis.com/v0/b/financial-fitness-cms.appspot.com/o/shared%2Fresources%20(1).png?alt=media&token=85b16b27-0ab8-43b1-9d8c-3270ce1af000`}
        />
        <Typography sx={{ p: 5, width: "100%", textAlign: "center" }}>
          In this section, you will find contact details for a range of
          organisations that can help you.
        </Typography>
        {content.resources.resourceSections.map((rs, idx) => {
          return (
            <>
              <Box sx={{ width: 250, m: "auto" }}>{resourceLotties[rs]}</Box>
              <Typography
                variant="h5"
                fontWeight="bold"
                color="primary.main"
                textAlign="center"
              >
                Organisations that can help you with {rs} issues
              </Typography>
              <Grid container>
                {content.resources.resources.map((r, idx) => {
                  if (r.section === rs) {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                          p: 2,
                          background: "white",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "primary.main",
                            display: "flex",
                            padding: 2,
                            height: 100,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 4,
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            {r.subsection}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            padding: 2,
                            height: 100,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            {r.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: 200,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt=""
                            src={r.logoURL}
                            style={{
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            height: 100,
                            [theme.breakpoints.up("md")]: {
                              height: 125,
                            },
                          }}
                        >
                          <Typography textAlign="center">
                            {r.description}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            p: 5,
                            width: "100%",
                            bottom: 0,
                          }}
                        >
                          <a
                            target="_blank"
                            href={r.link}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant="contained"
                              sx={{ backgroundColor: "warning.main" }}
                            >
                              Click here to visit {r.name}
                            </Button>
                          </a>
                        </Box>
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Divider
                sx={{ backgroundColor: "black", width: "50%", margin: "auto" }}
              />
            </>
          );
        })}
      </Grid>
      <ButtonWArrow theme={theme} />
      <Grid item xs={12} mt={5}>
        <Typography
          variant="h1"
          sx={{
            p: 3,
            margin: "auto",
            textAlign: "center",
            fontWeight: "bold",
            color: "primary.main",
            [theme.breakpoints.down("md")]: {
              fontSize: "2em",
            },
          }}
        >
          Any questions?
        </Typography>
        {}
        <Typography>
          If you have any questions, you can contact us at{" "}
          <a target="_blank" href="mailto:hello@helpandadvice.co.uk">
            hello@helpandadvice.co.uk
          </a>{" "}
          {content.paragraphs[1]}
        </Typography>
        <Typography py={2}>
          This content is for guidance and educational purposes only and is
          generic in nature. People & Technology Limited, or its consumer
          website, HelpandAdvice.co.uk, do not offer regulated financial advice.
          We are a guidance service only. Please seek independent financial
          advice from a suitably qualified and regulated professional adviser
          for financial issues or other suitable professionals for other issues
          that may be raised through the wellbeing assessment tool or on the
          Help and Advice website.
          <br></br>
          <br></br>
          People & Technology Limited are registered in England & Wales (company
          number - 13319892). Data Protection Registration - ZB223920. Please
          click{" "}
          <a target="_blank" href="https://helpandadvice.co.uk/privacy-policy/">
            here
          </a>{" "}
          to view our privacy policy.
        </Typography>
      </Grid>
    </Grid>
  );
}
