import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";

const SD = styled.div`
  width: 100%;
  max-width: 1000px;
  // margin: auto;
`;

const PB = styled.div`
  display: block;
  margin: 10px auto;
  background: linear-gradient(
    to left,
    ${(p) =>
      p.theme.palette.primary.main + ", " + p.theme.palette.secondary.main}
  );
  height: 10px;
  border-radius: 10px;
  width: ${({ ps, max }) => (ps / max) * 99}%;
  transition: 0.5s ease;
`;

export default function ProgressBar({ position, sections }) {
  const theme = useTheme();
  const max = sections[position[0]].questions.length;

  return (
    <SD>
      {/* <PB ps={position[1] + 1} max={max} theme={theme} /> */}
      <Box sx={{ display: "block", width: "100%", textAlign: "center" }}>
        <Typography sx={{ color: "gray" }}>
          {position[1] + 1}/{max}
        </Typography>
      </Box>
    </SD>
  );
}
