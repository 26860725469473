import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import budgetIcon from "../assets/icons/budgeting.png";
import debtsIcon from "../assets/icons/debts.png";
import healthIcon from "../assets/icons/health.png";
import householdIcon from "../assets/icons/household.png";
import payIcon from "../assets/icons/pay.png";
import pensionsIcon from "../assets/icons/pensions.png";
import planningIcon from "../assets/icons/planning.png";

import budgetIconArla from "../assets/icons/budgetingArla.png";
import debtsIconArla from "../assets/icons/debtsArla.png";
import healthIconArla from "../assets/icons/healthArla.png";
import householdIconArla from "../assets/icons/householdArla.png";
import payIconArla from "../assets/icons/payArla.png";
import pensionsIconArla from "../assets/icons/pensionsArla.png";
import planningIconArla from "../assets/icons/planningArla.png";

export const getIcon = (section, orgName) => {
  const generic = {
    budgeting: budgetIcon,
    debts: debtsIcon,
    health: healthIcon,
    household: householdIcon,
    renter: householdIcon,
    homeowner: householdIcon,
    pay: payIcon,
    pensions: pensionsIcon,
    planning: planningIcon,
  };

  const arla = {
    budgeting: budgetIconArla,
    debts: debtsIconArla,
    health: healthIconArla,
    household: householdIconArla,
    renter: householdIconArla,
    homeowner: householdIconArla,
    pay: payIconArla,
    pensions: pensionsIconArla,
    planning: planningIconArla,
  };

  if (orgName === "arla") {
    return arla[section];
  }

  return generic[section];
};

export const StyledIcon = styled.img`
  // width: ${(p) => p.width / 4};
  // width: 60%;
  margin: auto;
  ${(p) => p.overlay}

  ${(p) => p.s};
  // box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
  //   6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
  //   12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
  //   22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
  //   41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
  //   100px 100px 80px rgba(0, 0, 0, 0.07);
`;

export const colorOverlays = {
  demo: "",
  arla: "",
  everton: "",
  helpandadvice: "",
  adidas: "filter: hue-rotate(20deg);",
  liverpool: "filter: hue-rotate(100deg);",
};

export default function Icon({ section, width = "100px", s, margin }) {
  const { organizationName } = useSelector((state) => state.login.config);

  return (
    <div
      style={{
        borderRadius: "50%",
        width,
        height: width,
        background: "whitesmoke",
        overflow: "hidden",
        margin: margin || "auto",
        display: "flex",
      }}
    >
      <StyledIcon
        src={getIcon(section, organizationName)}
        width={width}
        s={s}
        overlay={colorOverlays[organizationName]}
      />
    </div>
  );
}
