import React from "react";
import Bubble from "../../../components/Bubble";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import { Typography, Button, Tooltip, useMediaQuery } from "@mui/material";
import Icon, { getIcon } from "../../../components/Icon";
import styled from "styled-components";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import { StyledIcon } from "../../../components/Icon";

export default function SideBubbles({
  headers,
  conditionals,
  endQuiz,
  setHeaders,
  modalInfo,
  lite,
  clickable,
  setPosition,
  setHistory,
  history,
  title,
}) {
  const updateHeaders = () => {
    const newHeaders = {
      ...headers,
      // [modalInfo.key]: { ...headers[modalInfo.key], grade: modalInfo.grade },
    };
    console.log(newHeaders);
    setHeaders(newHeaders);
  };

  const eq = () => {
    updateHeaders();
    endQuiz();
  };

  //jump to section
  const handleClick = (section) => {
    if (!clickable || headers[section].grade !== "") {
      return null;
    }

    // jump to section and wipe section
    setPosition([section, 0]);
    setHistory({ ...history, [section]: [] });
  };

  const theme = useTheme();

  console.log(headers);

  const isMobile = useMediaQuery("(max-width: 900px)");

  return (
    <Box
      sx={{
        width: "100%",
        overflowY: "scoll",
        justifyContent: "flex-start",
        display: "flex",
        flexDirection: "column",
        borderRadius: 5,
        ...(isMobile && { margin: "20px auto" }),
        mt: 3,
        maxWidth: "300px",
        p: isMobile ? 0 : 2,
        boxShadow: `
        0px 0px 2.2px rgba(0, 0, 0, 0.02),
        0px 0px 5.3px rgba(0, 0, 0, 0.028),
        0px 0px 10px rgba(0, 0, 0, 0.035),
        0px 0px 17.9px rgba(0, 0, 0, 0.042),
        0px 0px 33.4px rgba(0, 0, 0, 0.05),
        0px 0px 80px rgba(0, 0, 0, 0.07)
      `,
      }}
    >
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* {!lite && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                m: "auto",
                borderRadius: 3,
              }}
            >
              <Typography
                py={2}
                fontWeight="bold"
                width="150px"
                fontSize="1.1em"
              >
                Your scores
              </Typography>
            </Box>
          </>
        )} */}

        {Object.keys(headers)
          .filter((section) => headers[section].order !== undefined)
          .sort((a, b) => {
            return headers[a].order - headers[b].order;
          })
          .map((section, idx) => {
            if (
              (conditionals.renter && section === "homeowner") ||
              (!conditionals.renter && section === "renter")
            ) {
              return <></>;
            }

            const title = ["renter", "homeowner"].includes(section)
              ? "Your Home"
              : headers[section].title;
            return (
              <Tooltip
                arrow
                placement="right"
                title={
                  headers[section].grade
                    ? ""
                    : "Complete this section to get your score"
                }
              >
                <Box
                  sx={{
                    ...(!isMobile && { flex: 1 }),
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    maxWidth: "230px",
                    alignItems: "center",
                    mx: "auto",
                    // margin: "auto",
                    cursor: clickable ? "pointer" : "auto",
                    borderRadius: 2,
                    "& *": {
                      fontWeight: "bold",
                    },
                    ...(!headers[section].grade && {
                      "&:hover": {
                        backgroundColor: "white",
                        transform: "scale(1.02)",
                      },
                    }),
                  }}
                  onClick={clickable ? () => handleClick(section) : null}
                >
                  {!lite && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          width: "50px",
                          textAlign: "center",
                        }}
                      >
                        <Icon
                          section={section}
                          width={"50"}
                          s={"height: 50px;"}
                        />
                        {/* <img
                          margin="auto"
                          width="100%"
                          src={getIcon(orgname, section)}
                          style={{

                          }}
                        /> */}
                      </Box>

                      <Box
                        sx={{
                          position: "relative",
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {!lite && (
                          <Typography
                            sx={{
                              m: "auto",
                              textAlign: "center",
                              // color: "secondary.main",
                              backgroundColor: "white",
                              p: isMobile ? 0 : 0.5,
                              zIndex: 2,
                              fontSize: "14px",
                            }}
                          >
                            {title}
                          </Typography>
                        )}
                      </Box>
                    </>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      p: 0,
                      my: 0,
                    }}
                  >
                    <Bubble
                      key={section}
                      score={headers[section].grade || "?"}
                      size={50}
                      title={""}
                      // maxWidth={"100px"}
                    />
                  </Box>

                  {lite && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          width: "50px",
                          textAlign: "center",
                        }}
                      >
                        <Icon
                          section={section}
                          width={"50"}
                          s={"height: 50px;"}
                        />
                        {/* <img
                          margin="auto"
                          width="100%"
                          src={getIcon(orgname, section)}
                          style={{

                          }}
                        /> */}
                      </Box>

                      <Box
                        sx={{
                          position: "relative",
                          height: "60",
                          background: "white",
                          flexDirection: "column",
                          flex: 1,
                          width: "100%",
                          zIndex: 999,
                        }}
                      >
                        <div
                          style={{
                            width: 2,
                            height: isMobile ? 20 : 40,
                            background: "grey",
                            position: "absolute",
                            left: "50%",
                            bottom: "110%",
                            transform: "translate( -50%, 0 )",
                          }}
                        />
                        <Typography
                          sx={{
                            m: "auto",
                            textAlign: "center",
                            // color: "secondary.main",
                            backgroundColor: "white",
                            p: isMobile ? 0 : 1,
                            zIndex: 2,
                            fontSize: isMobile ? "9px" : "12px",
                            fontWeight: "light",
                            maxWidth: "100%",
                          }}
                        >
                          {title}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Tooltip>
            );
          })}
      </Box>
      {/* {!lite && (
        <>
          <Button
            variant="contained"
            color="primary"
            sx={{
              p: 2,
              m: "10px auto",
              borderRadius: 10,
              color: "white",
              textTransform: "none",
              // "&:hover": {
              //   backgroundColor: "warning.main",
              // },
            }}
            onClick={eq}
            theme={theme}
          >
            <Typography
              sx={{
                px: 2,
              }}
            >
              End Assessment And Get Final Score
            </Typography>
          </Button>
          <Typography fontSize="0.8em">
            Final score is based on completed sections only
          </Typography>
        </>
      )} */}
    </Box>
  );
}
