import React, { useState, useEffect } from "react";
import {
  getMultiFactorResolver,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { doc, getDoc } from "firebase/firestore";

import { db, auth } from "../../firebase";

import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Modal,
  Dialog,
} from "@mui/material";

import { actions } from "../logInScreen/slice";
import { fetchUser } from "./actions";

import { randomBytes, createCipheriv } from "crypto";

export default function Login({
  mode,
  backgroundColor,
  setRedirect,
  organizationName,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.querySelector("#truendoPrivacyPanel");
    if (!script) {
      const cookieTag = document.createElement("script");
      cookieTag.setAttribute("id", "truendoAutoBlock");
      cookieTag.setAttribute(
        "data-siteid",
        "f3caeef3-5869-4b4b-9579-7c952faa2c5d"
      );
      cookieTag.src = "https://cdn.priv.center/pc/truendo_cmp.pid.js";

      document.body.appendChild(cookieTag);
    }

    const interval = setInterval(() => {
      const isOpen = document.querySelector(".tru_open");
      const cookies = document.cookie;
      if (!isOpen && !cookies.includes("truendo")) {
        document.querySelector(".truendo_fab").click();
      }
    }, [1500]);

    return () => {
      try {
        clearInterval(interval);
        document.body.removeChild(document.querySelector("#truendoAutoBlock"));
        document.querySelector("#truendo_container").remove();
      } catch (e) {
        console.log(e);
      }
    };
  }, []);

  const [showMaintenanceModal, setShowMaintenanceModal] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
    loading: false,
    error: false,
    success: false,
  });

  const updateField = (field, value) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const LogIn = async (e) => {
    try {
      e.preventDefault();
      updateField("loading", true);

      // authenticate
      const userCreds = await signInWithEmailAndPassword(
        auth,
        state.email,
        state.password
      );

      // fetch user
      await fetchUser(dispatch, userCreds.user, organizationName);
      if (!process.env.REACT_APP_IS_TEST && organizationName !== "demo") {
        setRedirect("/home");
        // setRedirect("/mfa/create?o=" + organizationName);
      } else {
        setRedirect("/home");
      }
    } catch (e) {
      console.log(e);

      const hasNumber = e?.message?.includes('"hasNumber":true');
      const invalidClient = e?.message?.includes('"validClient":false');

      if (invalidClient) {
        setShowMaintenanceModal(true);
      }
      // if login fails due to custom mfa
      else if (e.code === "auth/internal-error") {
        const key = btoa(state.password);
        localStorage.setItem("key", key);

        setRedirect(
          "/mfa?o=" +
            organizationName +
            `&new=${hasNumber ? 0 : 1}&creds=${state.email}`
        );
      }

      updateField("error", true);
      updateField("loading", false);
    }
  };

  const handleReset = async () => {
    try {
      await sendPasswordResetEmail(auth, resetEmail);
    } catch (e) {}
    setResetSent(true);
    setTimeout(() => {
      setShowReset(false);
    }, 2000);
  };

  if (mode !== "login") {
    return null;
  }

  return (
    <Grid container>
      <Modal
        open={showReset}
        onClose={() => setShowReset(false)}
        sx={{ display: "flex" }}
      >
        <Box
          sx={{
            m: "auto",
            p: 3,
            backgroundColor: "white",
            width: 500,
            maxWidth: "100vw",
            borderRadius: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {resetSent ? (
            <Alert severity="success">Email sent</Alert>
          ) : (
            <>
              <Typography p={2}>
                Please submit your email below, we will email you the steps to
                recover your password.
              </Typography>
              <TextField
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                label="email"
              />
              <Button
                sx={{ p: 2, my: 2 }}
                variant="contained"
                onClick={handleReset}
              >
                Submit
              </Button>
            </>
          )}
        </Box>
      </Modal>
      {state.loading && <CircularProgress sx={{ margin: "auto" }} size={100} />}
      {!state.loading && (
        <>
          <form
            onSubmit={(e) => LogIn(e)}
            style={{
              backgroundColor: "white",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            {state.error && (
              <Box sx={{ p: 2, width: "100%" }}>
                <Alert severity="error">Login error</Alert>
              </Box>
            )}

            <Box sx={{ p: 2, width: "100%" }}>
              <TextField
                label="email"
                fullWidth
                onChange={(e) => updateField("email", e.target.value)}
                value={state.email}
              />
            </Box>
            <Box sx={{ p: 2, width: "100%" }}>
              <TextField
                label="password"
                fullWidth
                type="password"
                onChange={(e) => updateField("password", e.target.value)}
                value={state.password}
              />
            </Box>
            <Box sx={{ p: 2, width: "100%" }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "warning.main",
                  p: 2,
                  width: "50%",
                }}
              >
                Log In
              </Button>
              <Box sx={{ p: 2, textAlign: "center" }}>
                <Button onClick={() => setShowReset(true)}>
                  Forgot password?
                </Button>
              </Box>
            </Box>
          </form>

          <Typography sx={{ color: "white", p: 4 }}>
            * You should log in with your primary email address. This is the
            email that we send copies of your assessment and any follow up
            emails too, which will either be your Company email address or the
            personal email you provided when you set up the account
            <br />
            <br /> If you are unable to access your account you can contact us
            on hello@helpandadvice.co.uk
          </Typography>
        </>
      )}
      <Dialog
        open={showMaintenanceModal}
        onClose={() => setShowMaintenanceModal(false)}
      >
        <Box p={2}>
          <Typography>
            The Financial Assessment Tool is currently being updated. It will be
            re-launched in the near future.
          </Typography>
        </Box>
      </Dialog>
    </Grid>
  );
}
