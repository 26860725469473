export const themeconfig = {
  adidas: {
    palette: {
      primary: {
        main: "#000000",
        light: "#1373ba",
      },
      secondary: {
        main: "#072663",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  everton: {
    palette: {
      primary: {
        main: "#003399",
        light: "#003399",
      },
      secondary: {
        main: "#072663",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  demo: {
    palette: {
      primary: {
        main: "#00989d",
        light: "#22aeb3",
      },
      secondary: {
        main: "#072663",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  helpandadvice: {
    palette: {
      primary: {
        main: "#00989d",
        light: "#22aeb3",
      },
      secondary: {
        main: "#072663",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  arla: {
    typography: {
      fontFamily: "Arla",
    },
    palette: {
      primary: {
        main: "#006a38",
        light: "#00376a",
      },
      secondary: {
        main: "#007595",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  liverpool: {
    typography: {
      fontFamily: "Arial",
    },
    palette: {
      primary: {
        main: "#dc0813",
        light: "#911712",
      },
      secondary: {
        main: "#000000",
        light: "#ffd700",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
};
