import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { actions } from "../logInScreen/slice";
import { CircularProgress } from "@mui/material";
import { Navigate } from "react-router";

export default function Logout() {
  const dispatch = useDispatch();
  const { organizationName: oName } = useSelector(
    (state) => state.login.config
  );

  const [organizationName, setorganizationName] = useState("");

  const logOut = async () => {
    signOut(auth).then(() => dispatch(actions.resetState()));
    if (oName) {
      setorganizationName(oName);
    }
  };

  useEffect(() => {
    logOut();
  }, []);

  if (!auth.currentUser) {
    return <Navigate to={`/${organizationName || ""}`} />;
  }

  return <CircularProgress size={100} />;
}
