import { LinearProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import FinancialEducationLogo from "../assets/images/financialeducationlogo.png";

const MyDiv = styled.div`
  && {
    z-index: 1000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #181818;
  }
`;

const MyImg = styled.img`
  && {
    animation: throb 3s 0s infinite;
    width: 150px;
    height: 150px;
    margin: auto;
    object-fit: contain;
  }
`;

export default function SplashScreen() {
  return (
    <MyDiv>
      <Box sx={{width: '50%', margin: 'auto'}}>
        <MyImg src={FinancialEducationLogo} />
        <LinearProgress />
      </Box>
    </MyDiv>
  );
}
