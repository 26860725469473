import React from "react";
import Bubble from "../../../components/Bubble";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";

export default function HeaderBubbles({ headers, conditionals }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        pt: 3,
        justifyContent: "space-evenly",
        maxWidth: "1000px",
        width: "100%",
        display: "flex",
        margin: "auto",
        [theme.breakpoints.down("md")]: {
          flexWrap: "wrap",
          maxWidth: "90%",
          justifyContent: "center",
        },
      }}
    >
      {Object.keys(headers)
        .filter((section) => headers[section].order !== undefined)
        .sort((a, b) => {
          return headers[a].order - headers[b].order;
        })
        .map((section) => {
          if (
            (conditionals.renter && section === "homeowner") ||
            (!conditionals.renter && section === "renter")
          ) {
            return <></>;
          }

          const title = ["renter", "homeowner"].includes(section)
            ? "Household Spending"
            : headers[section].title;
          return (
            <Box sx={{ flex: "1" }}>
              <Bubble
                key={section}
                score={headers[section].grade}
                size={50}
                title={title}
                maxWidth={"100px"}
              />
            </Box>
          );
        })}
    </Box>
  );
}
