import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { doc, deleteDoc } from "firebase/firestore";
import { db, auth } from "../../firebase";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { makeHTML } from "./utils";

export default function BasicMenu({ assessment, reload, theme, name }) {
  const [showDelete, setShowDelete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const resendEmail = async (quizId) => {
    await axios({
      method: "POST",
      url: "/.netlify/functions/emailResults",
      // url: "https://upbeat-montalcini-34e93f.netlify.app/.netlify/functions/emailResults",
      data: JSON.stringify({
        body: makeHTML(assessment, name, theme.palette.primary.main),
        recipient: auth.currentUser.email,
        // recipient: "harrymenen@gmail.com",
      }),
    });
  };

  const confirmDelete = async () => {
    setLoading(true);
    await deleteDoc(
      doc(db, `users/${auth.currentUser.uid}/quizHistory/${assessment.id}`)
    );
    await reload();
    setShowDelete(false);
    handleClose();
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={showDelete}
        onClose={() => setShowDelete(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            p: 4,
            background: "white",
            borderRadius: 3,
            maxWidth: 500,
            margin: "50px auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <CircularProgress size="100px" sx={{ m: "auto" }} />
          ) : (
            <>
              <Typography textAlign="center">
                Are you sure you want to delete this assessment?
              </Typography>
              <Button
                sx={{ m: "10px auto" }}
                onClick={confirmDelete}
                variant="contained"
              >
                Delete
              </Button>
            </>
          )}
        </Box>
      </Modal>
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => setShowDelete(true)}>
            Delete Assessment
          </MenuItem>
          <MenuItem onClick={resendEmail}>Resend results email</MenuItem>
        </Menu>
      </div>
    </>
  );
}
