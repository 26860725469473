import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  MenuItem,
  FormGroup,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
} from "@mui/material";

import { fetchCaptureFields, fetchUser, registerUser } from "./actions";
import { useLocation } from "react-router";
import { CheckBox } from "@mui/icons-material";

const StyledTextField = styled(TextField)`
  ${(p) =>
    p.col
      ? `
    .MuiInputLabel-formControl{
      color: blue;
      background: white;
    }
    .MuiSelect-select{
      border: 1px solid blue;
    }
  `
      : ""}
`;

const emailMatch = {
  adidas: ["@adidas-group.com", "@adidas.com"],
  everton: ["@evertonfc.com"],
  demo: ["@people-tech.co.uk", "autotrader.co.uk"],
  arla: ["@arlafoods.com"],
  helpandadvice: ["@helpandadvice.co.uk"],
  liverpool: ["@liverpoolfc.com"],
};

const orgnameMatch = {
  adidas: "adidas",
  everton: "Everton Football Club",
  demo: "Widgets",
  arla: "Arla",
  helpandadvice: "Help & Advice",
  liverpool: "Liverpool FC",
};

export default function Register({ mode, setRedirect }) {
  const location = useLocation();
  const organizationName = location.pathname.split("/")[1];

  const dispatch = useDispatch();

  const [state, setState] = useState({
    loading: false,
    error: false,
    success: false,
  });

  const [checked, setChecked] = useState(false);
  const [fields, setFields] = useState({
    firstName: {
      card: 0,
      value: "",
      label: "First name",
      isValid: (fields) => fields.firstName.value,
      tooltip: "",
    },
    companyEmail: {
      card: 0,
      value: "",
      label: (org) =>
        org === "arla" || org === "adidas"
          ? `${orgnameMatch[org]} email address or employee number`
          : `${orgnameMatch[org]} email address`,
      isValid: (fields) => {
        const standardMatch =
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            fields.companyEmail.value
          ) &&
          emailMatch[organizationName].some((domain) => {
            return fields.companyEmail.value.includes(domain);
          });

        // if arla, allow number too
        if (organizationName === "arla") {
          return (
            standardMatch ||
            ((fields.companyEmail.value.length === 7 ||
              fields.companyEmail.value.length === 8) &&
              ["0", "1"].includes(fields.companyEmail.value[0]))
          );
        }
        // if adidas, allow number too
        if (organizationName === "adidas") {
          return (
            standardMatch ||
            fields.companyEmail.value.length === 6 ||
            (fields.companyEmail.value.length === 8 &&
              fields.companyEmail.value.slice(0, 2) === "00")
          );
        }
        return standardMatch;
      },
      tooltip: (orgName) => {
        if (orgName === "arla") {
          return `You will need this to access the ${orgnameMatch[orgName]} version of the assessment tool. Please note that you will need to use your personal email address, to log in, in the future, if you use an employee number to create your account. If you still can't log in please email ONEHR@arlafoods.com.`;
        }
        if (orgName === "adidas") {
          return `You will need this to access the ${orgnameMatch[orgName]} version of the assessment tool. Please note that you will need to use your personal email address, to log in, in the future, if you use an employee number to create your account.`;
        }
        return `You will need this to access the ${orgnameMatch[orgName]} version of the assessment tool.`;
      },
      helperText: (orgName) =>
        orgName === "arla"
          ? "Invalid email or company number"
          : `Invalid email`,
    },
    personalEmail: {
      card: 0,
      value: "",
      label: "Personal email address",
      isValid: (fields) =>
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          fields.personalEmail.value
        ),
      tooltip: (orgName) => {
        return `You will need this to access your account if you ever leave ${orgnameMatch[orgName]} and no longer have access to that email address.`;
      },
      helperText: "Invalid email",
    },
    useCompanyAsPrimary: {
      card: 0,
      value: false,
      label: (orgName) => `Choose preferred correspondence email address.`,
      control: "switch",
      options: (orgName) => {
        return [
          { label: "Use my personal email", value: false },
          { label: `Use my ${orgnameMatch[orgName]} email`, value: true },
        ];
      },
      tooltip:
        "This will become your primary email address for all communications, including copies of your asssessment results and follow up emails.",
    },
    password: {
      card: 0,
      value: "",
      label: "Create password",
      type: "password",
      isValid: (fields) =>
        fields.password.value.length > 7 &&
        /[!@#\$%\^\&*\)\(+=._-]/.test(fields.password.value) &&
        /[0-9]/.test(fields.password.value),
      tooltip: `\n \n Must be at least 8 characters long. Min 1 special character. Min 1 number`,
      helperText: "Invalid password",
    },
    confirmPassword: {
      card: 0,
      value: "",
      label: "Confirm password",
      type: "password",
      isValid: (fields) =>
        fields.confirmPassword.value === fields.password.value,
      helperText: "Password doesn't match",
    },
  });

  const updateField = (field, value) => {
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value,
      },
    });
  };

  const Register = async (e) => {
    try {
      e.preventDefault();
      setState({ loading: true, error: false, success: false });
      const user = await registerUser(fields, organizationName);
      console.log(user);
      await fetchUser(dispatch, user, organizationName);

      if (!process.env.REACT_APP_IS_TEST && organizationName !== "demo") {
        // setRedirect("/mfa/create?o=" + organizationName);
        // setRedirect("/mfa/create?o=" + organizationName);
        setRedirect("/home");
      } else {
        setRedirect("/home");
      }
    } catch (e) {
      console.log(e);
      let err = "";
      if (e.message.split("Firebase: ")[1]) {
        err = e.message.split("Firebase: ")[1];
      }

      setState({
        loading: false,
        error: err,
        success: false,
      });
    }
  };

  const loadCaptureData = async () => {
    try {
      setState({ error: false, loading: true, success: false });
      await fetchCaptureFields(fields, setFields, organizationName);
      setState({ error: false, loading: false, success: false });
    } catch (e) {
      console.log(e);
      setState({ success: false, error: true, loading: false });
    }
  };

  useEffect(() => {
    loadCaptureData();
  }, []);

  console.log(organizationName);
  const formValid =
    Object.keys(fields).every((f) => {
      return (
        (fields[f].isValid
          ? fields[f].isValid(fields, organizationName)
          : true) &&
        fields[f].value !== "" &&
        fields[f].value !== null
      );
    }) && checked;

  console.log(fields);

  if (mode !== "register") {
    return null;
  }

  console.log(state);

  return (
    <Grid container>
      {state.loading && (
        <CircularProgress sx={{ margin: "auto" }} size="100px" />
      )}
      {!state.loading && (
        <>
          <form
            onSubmit={(e) => Register(e)}
            style={{
              backgroundColor: "white",
              width: "100%",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <Typography variant="h5" p={2}>
              Create your account
            </Typography>
            {state.error && (
              <Box sx={{ p: 2, width: "100%" }}>
                <Alert severity="error">{state.error}</Alert>
              </Box>
            )}
            {Object.keys(fields)
              .filter((f) => fields[f].card === 0)
              .map((f) => {
                const field = fields[f];
                return Input(f, field, updateField, fields, organizationName);
              })}
            {Object.keys(fields).filter((f) => fields[f].card === 1).length >
              0 && <Typography variant="h5">A little about you</Typography>}
            {Object.keys(fields)
              .filter((f) => fields[f].card === 1)
              .map((f) => {
                const field = fields[f];
                return Input(
                  f,
                  field,
                  updateField,
                  fields,
                  organizationName,
                  "blue"
                );
              })}

            <Box>
              <Typography variant="h5">Permissions</Typography>
              <Typography sx={{ p: 4 }}>
                Please note that your individual results are not shared with
                your employer. Aggregated results are produced to help identity
                which areas {orgnameMatch[organizationName]} should focus on
                when providing support to staff with their financial wellbeing.
                By providing your email address you will also consent to a copy
                of your assessment being emailed to you and to receive follow up
                emails that have additional tips and guidance on ways you can
                improve your financial wellbeing. You will however be able to
                unsubscribe at any time.
              </Typography>
              <FormControlLabel
                label="Please check the box to confirm your agreement and create your personal account."
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                }
              />
            </Box>

            <Button
              type="submit"
              disabled={!formValid}
              variant="contained"
              sx={{
                my: 3,
                backgroundColor: "warning.main",
              }}
            >
              Create account
            </Button>
          </form>
        </>
      )}
    </Grid>
  );
}

const Input = (fieldName, field, updateField, fields, orgName, color) => {
  const valid = field.isValid ? field.isValid(fields) : true;
  let helperText = "";

  if (field.value && !valid) helperText = field.helperText || "Required";

  const getHelperText = () => {
    if (
      orgName === "adidas" &&
      fieldName === "companyEmail" &&
      !field.value.includes("@")
    ) {
      return "";
    }
    return typeof helperText === "string" ? helperText : helperText(orgName);
  };
  const getError = () => {
    if (
      orgName === "adidas" &&
      fieldName === "companyEmail" &&
      !field.value.includes("@")
    ) {
      return false;
    }
    return field.value && !valid;
  };
  return (
    <Grid container>
      <Grid item xs={12} md={6} p={2}>
        {field.control !== "switch" ? (
          <>
            {fieldName === "password" && (
              <Typography sx={{ fontWeight: "bold", textAlign: "left", py: 2 }}>
                Create password
              </Typography>
            )}
            <StyledTextField
              col={color}
              select={field.options}
              label={
                typeof field.label === "string"
                  ? field.label
                  : field.label(orgName)
              }
              fullWidth
              onChange={(e) => updateField(fieldName, e.target.value)}
              value={field.value}
              type={field.type || "text"}
              helperText={getHelperText()}
              error={getError()}
            >
              {field.options &&
                (typeof field.options !== "function"
                  ? field.options
                  : field.options(orgName)
                ).map((o) => {
                  return (
                    <MenuItem value={o} key={o}>
                      {o}
                    </MenuItem>
                  );
                })}
            </StyledTextField>
          </>
        ) : (
          <FormGroup sx={{ border: "1px solid gray" }}>
            <Typography
              sx={{
                position: "relative",
                bottom: "10px",
                left: "10px",
                background: "white",
                display: "inline-block",
                width: "350px",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              {field.label()}
            </Typography>
            <RadioGroup
              value={field.value}
              onChange={(e) =>
                updateField(fieldName, e.target.value === "true")
              }
            >
              {(typeof field.options !== "function"
                ? field.options
                : field.options(orgName)
              ).map((o) => {
                return (
                  <FormControlLabel
                    sx={{ margin: "auto" }}
                    key={o.label}
                    value={o.value}
                    control={<Radio />}
                    label={o.label}
                  />
                );
              })}
            </RadioGroup>
          </FormGroup>
        )}
      </Grid>
      <Grid item xs={12} md={6} p={2} display="flex">
        {field.tooltip && (
          <Typography textAlign="left" my="auto">
            {typeof field.tooltip === "string"
              ? field.tooltip
              : field.tooltip(orgName)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
