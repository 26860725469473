import React, { useEffect, useState } from "react";
import Nav from "./Nav";

import { auth } from "../firebase";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";

const allowedPaths = [
  "/",
  "/adidas",
  "/everton",
  "/logout",
  "/demo",
  "/arla",
  "/helpandadvice",
  "/mfa",
  "/liverpool"
];

export default function Layout({ children, showNav }) {
  console.log({ currentUser: auth.currentUser });
  const location = useLocation();

  const { secondaryEmail } = useSelector((state) => state.login.config);

  let expired = false;
  if (auth.currentUser) {
    expired =
      moment() > moment(auth.currentUser.stsTokenManager.expirationTime);
  }

  if (
    (expired || !secondaryEmail) &&
    !allowedPaths.includes(location.pathname)
  ) {
    return <Navigate to="/logout" />;
  }

  return (
    <>
      {process.env.REACT_APP_IS_TEST && (
        <div style={{ background: "red", "min-width": "100vw" }}>test env</div>
      )}
      {showNav && <Nav />}
      {children}
    </>
  );
}
