import React, { useEffect } from "react";

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Button,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "./Logo";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { useLocation } from "react-router";
import peopleTechLogo from "../assets/images/peopleTechLogo.png";
import styled from "styled-components";

const Image = styled.img`
  && {
    position: absolute;
    left: 20px;
    width: 100px;
    height: 80px;
    top: 0;
    object-fit: contain;
    padding: 10px;
  }
`;

export default function Nav() {
  const { organizationName } = useSelector((state) => state.login.config);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const { isOnResultsPage } = useSelector((state) => state.login);
  const shouldMinimize = location.pathname === "/quiz" && !isOnResultsPage;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
            }}
          >
            <Link to={`/landing/${organizationName}`}>
              <Image src={peopleTechLogo} />
            </Link>
            <Grid container display="flex" justifyContent={"center"}>
              <Grid item xs={0} md={shouldMinimize ? 3 : 0}></Grid>
              <Grid
                item
                xs={0}
                md={8}
                sx={{
                  justifyContent: shouldMinimize ? "flex-start" : "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "1000px",
                    maxHeight: "65px",
                    p: 1,
                  }}
                >
                  {!(
                    organizationName == "liverpool" &&
                    window.location.pathname.includes("landing")
                  ) && (
                    <Link to={`/landing/${organizationName}`}>
                      <Logo
                        useSecondary={organizationName === "adidas"}
                        width={organizationName == "liverpool" ? 40 : 65}
                        companyName={organizationName}
                      />
                    </Link>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
              <MenuItem onClick={handleClose}>My Assessments</MenuItem>
            </Link>
            <Link
              to={`/landing/${organizationName}`}
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>Resources</MenuItem>
            </Link>
            <Link
              to="/account"
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>Account</MenuItem>
            </Link>
            <Link to="/faq" style={{ textDecoration: "none", color: "black" }}>
              <MenuItem onClick={handleClose}>FAQ</MenuItem>
            </Link>
            {/* <Link to="/secure-login" style={{ textDecoration: "none", color: "black" }}>
              <MenuItem onClick={handleClose}>mfa</MenuItem>
            </Link> */}
            <Link
              to="/logout"
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem onClick={handleClose}>
                <Logout /> Log out
              </MenuItem>
            </Link>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
